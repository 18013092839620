import { SET_SLIPPAGE, SET_DARKMODE, SET_NETWORK, SET_USEAPI } from "../constants";
import { CHAINS_IDS } from "./../../constants/chains";

// helper
import LS from "./../../helpers/storage";

// Constants
let baseState = {
  slippage: "0.10",
  darkmode: false,
  network: CHAINS_IDS.MAINNET,
  useapi: true,
};
let initialState = {};

try {
  let settings = JSON.parse(LS.getItemDevice("settings"));
  initialState = Object.assign(baseState, settings);
} catch (error) {
  initialState = baseState;
}

// Reducer
export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USEAPI:
      return { ...state, useapi: action.payload };
    case SET_SLIPPAGE:
      return { ...state, slippage: action.payload };
    case SET_DARKMODE:
      return { ...state, darkmode: action.payload };
    case SET_NETWORK: {
      let settings = {}
      Object.assign(settings, state)
      settings.network = action.payload
      try {
        LS.setItemDevice("settings", JSON.stringify(settings))
      } catch (e) {
        console.log(e)
      }
      return { ...state, network: action.payload };
    }
    default:
      return state;
  }
}
