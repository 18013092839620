import { Contract, Provider, Signer } from "koilib";
import { CONFIG_BASE } from "./../constants/configs";

// abis
import CoreAbi from "./../assets/abis/core-abi.json";
import PeripheryAbi from "./../assets/abis/periphery-abi.json";
import StakingAbi from "./../assets/abis/staking-abi.json";
import GovernanceAbi from "./../assets/abis/governance-abi.json";
import TokenAbi from "./../assets/abis/token-abi.json";
import NameserviceAbi from "./../assets/abis/nameservice-abi.json";

// variables
const provider = new Provider([ import.meta.env.VITE_RPC ]);
const signer = Signer.fromSeed("demo seed");

export let PeripheryContract = (_provider, _signer) => new Contract({
  id: CONFIG_BASE.contracts.periphery.address,
  abi: PeripheryAbi,
  provider: _provider ? _provider : provider,
  signer: _signer ? _signer : signer,
})

export let CoreContract = (_address, _provider, _signer) => new Contract({
  id: _address,
  abi: CoreAbi,
  provider: _provider ? _provider : provider,
  signer: _signer ? _signer : signer,
})

export let StakingContract = (_provider, _signer) => new Contract({
  id: "18GFftZCZibxAgtHHUSSwVpzxWa7GYAAVr",
  abi: StakingAbi,
  provider: _provider ? _provider : provider,
  signer: _signer ? _signer : signer,
})

export let GovernanceContract = (_provider, _signer) => new Contract({
  id: "16UPCAcSqLpMfsaVLxMsDJtmLsGXyc1BRQ",
  abi: GovernanceAbi,
  provider: _provider ? _provider : provider,
  signer: _signer ? _signer : signer,
})

export let TokenContract = (address, _provider, _signer) => new Contract({
  id: address,
  abi: TokenAbi,
  provider: _provider ? _provider : provider,
  signer: _signer ? _signer : signer,
})

export let NameServiceContract = (_provider, _signer) => new Contract({
  id: CONFIG_BASE.chain.contracts.nameservice,
  abi: NameserviceAbi,
  provider: _provider ? _provider : provider,
  signer: _signer ? _signer : signer,
})
