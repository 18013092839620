import { SET_IMPORT_ASSET_ONE, SET_IMPORT_ASSET_TWO } from "../constants"

export const setImportAssetOne = (data) => ({
  type: SET_IMPORT_ASSET_ONE,
  payload: data
})

export const setImportAssetTwo = (data) => ({
  type: SET_IMPORT_ASSET_TWO,
  payload: data
})
