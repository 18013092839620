import { SET_TOKEN_RECEIVED, SET_TOKEN_SEND, INITIALIZE_SWAP, SET_SWAPS_TIMING } from "../constants"


// Constants
const initialState = {
  loading: true,
  timing: Date.now(),
  configs: {
    fee_on: true,
    fee_to: ""
  },
  send: null,
  received: null,
}

// Reducer
export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TOKEN_SEND:
      return { ...state, send: action.payload };
    case SET_TOKEN_RECEIVED:
      return { ...state, received: action.payload };
    case SET_SWAPS_TIMING:
      return { ...state, timing: action.payload };
    case INITIALIZE_SWAP:
      return { ...action.payload };
    default:
      return state
  }
}
