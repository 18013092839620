import { WalletConnectKoinos } from "@armana/walletconnect-koinos-sdk-js";
import { Methods } from "@armana/walletconnect-koinos-sdk-js";

/**
 * Initializes WalletConnect
 * @returns 
 */
export const initializeWalletConnect = () => {
  const projectId = import.meta.env.VITE_WALLETCONNECT_PROJECTID;
  return new WalletConnectKoinos(
    {
      projectId,
      // your application information
      metadata: {
        name: "KoinDX",
        description: "KoinDX - DeFi Unleashed",
        url: "https://app.koindx.com",
        icons: [
          "~/assets/logo_white.svg",
        ],
      },
      modalOptions: {
        explorerRecommendedWalletIds: ["de9e8421961e35c5e35cb63e3cd7be9af328a62c7b5a11f95ca116bf128a7424"],
      },
    }
  );
}

export const WALLET_CONNECT_METHODS = [
  Methods.SignAndSendTransaction,
  Methods.PrepareTransaction,
  Methods.WaitForTransaction,
  Methods.ReadContract,
  Methods.GetAccountRc,
  Methods.GetTransactionsById
]

export const SUPPORTED_WALLETS = ["kondor", "mkw", "walletconnect"]