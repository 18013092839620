import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { get as _get } from "lodash";
// components mui
import { Box, Modal, TextField, Tooltip, ToggleButton, ToggleButtonGroup, Typography, styled, Switch, FormControlLabel } from "@mui/material";

// Actions
import { setModalData, setModal } from "../../../redux/actions/modals";
import { setSlippage, setUseAPI } from "../../../redux/actions/settings";

// components
import ModalHeader from "./../ModalHeader";

// helpers
import LS from "./../../../helpers/storage";

const ModalSettingsApp = () => {
  // Dispatch to call actions
  const dispatch = useDispatch();

  // selectors
  const settingSelector = useSelector((state) => state.settings);
  const currentModal = useSelector((state) => state.modals.modal);

  // state
  const slippage = _get(settingSelector, "slippage", "0.10");
  const useapi = _get(settingSelector, "useapi", true)

  const handleChange = (e) => {
    let validated = String(e.target.value);
    if (validated >= 50) {
      validated = 49.99;
    }
    LS.settings({ slippage: validated });
    dispatch(setSlippage(validated));
  };

  const closeModal = () => {
    dispatch(setModal(null));
    dispatch(setModalData(null));
  };

  const handleChangeUseApi = (e) => {
    let useApiValue = e.target.checked
    LS.settings({ useapi: useApiValue });
    dispatch(setUseAPI(useApiValue));
  }

  const KoindxToggleButton = styled(ToggleButton)(({ theme }) => ({
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.paper,
    "&.Mui-selected": {
      backgroundColor: theme.palette.background.light,
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.background.paper,
      },
    },
  }));

  return (
    <Modal open={currentModal === "SettingsApp"} onClose={closeModal} sx={{ display: "flex" }}>
      <Box
        sx={{
          padding: "10px",
          width: "100%",
          height: "auto",
          maxWidth: "420px",
          bgcolor: "background.default",
          borderRadius: "10px",
          margin: "auto auto"
        }}
      >
        <ModalHeader title={"SETTINGS"} closeFunction={closeModal} />

        <Box sx={{ paddingX: "15px", marginTop: "10px" }}>
          <Tooltip placement="top" title="Slippage - the difference between the expected price of an asset and the actual price at which the trade is executed.">
            <Typography variant="body1" component="p">
              Slippage Tolerance (%)
            </Typography>
          </Tooltip>
          <Box sx={{ marginTop: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <ToggleButtonGroup color="primary" size="small" value={slippage} exclusive onChange={handleChange}>
              <KoindxToggleButton variant="kndx" sx={{ padding: "8px", borderRadius: "10px", fontSize: "18px" }} value="0.05">
                0.05
              </KoindxToggleButton>
              <KoindxToggleButton variant="kndx" sx={{ padding: "8px", fontSize: "18px" }} value="0.10">
                0.10
              </KoindxToggleButton>
              <KoindxToggleButton variant="kndx" sx={{ padding: "8px", borderRadius: "10px", fontSize: "18px" }} value="0.20">
                0.20
              </KoindxToggleButton>
            </ToggleButtonGroup>

            <Box sx={{ borderRadius: "10px", paddingX: "5px" }}>
              <TextField
                sx={{
                  backgroundColor: "background.light",
                  borderRadius: "10px",
                  m: 1, width: "100%", "& .MuiInputBase-root": {
                    color: "text.main", borderRadius: "10px", "& fieldset": {
                    }, "&:hover fieldset": {
                    }
                  }
                }} size="small" value={slippage} onChange={handleChange} />
            </Box>
          </Box>
          <Box>
            <FormControlLabel control={<Switch checked={useapi} onChange={handleChangeUseApi} />} label="Use KoinDX API" />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};



export default ModalSettingsApp;
