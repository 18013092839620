import { SET_IMPORT_ASSET_ONE, SET_IMPORT_ASSET_TWO } from "../constants";

// state
let baseState = {
  assetOne: null,
  assetTwo: null
}


// Reducer
export default function chatReducer(state = baseState, action) {
  switch (action.type) {
    case SET_IMPORT_ASSET_ONE:
      return { ...state, assetOne: action.payload };
    case SET_IMPORT_ASSET_TWO:
      return { ...state, assetTwo: action.payload };
    default:
      return state;
  }
}
