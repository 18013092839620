import React from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
// Actions
import { setModal } from "../../../redux/actions/modals";

export const ConnectButton = () => {
  const dispatch = useDispatch()
  return (
    <Button size="small" variant="contained" sx={{ width: "100%" }} onClick={() => dispatch(setModal("Connect"))}>
      Connect
    </Button>
  )
}