import { get as _get } from "lodash";
import { loadTokenData } from "../utils/token";
import { regexBtcAddress } from "../utils/regex";
import { CONFIG_BASE } from "./../constants/configs"
// contracts
import { PeripheryContract } from "./contracts";

// helpers
import LS from "./storage";

// request
import TokensService from "./../services/tokens";

/**
 * Initializes the tokens from the token list api and localstorage
 * @returns 
 */
export const InitializeTokens = async () => {
  let result = {
    loading: false,
    base_tokens: [],
    custom_tokens: []
  }

  try {
    let tokensService = new TokensService();

    let tokensResult = {};
    switch (import.meta.env.VITE_CHAIN) {
      case "MAINNET":
        tokensResult = await tokensService.getMainnet()
        break;
      case "HARBINGER":
        tokensResult = await tokensService.getHarbinger()
        break;
      default:
        break;
    }

    let tokensFinal = _get(tokensResult, "tokens", []);

    let custom_tokens = JSON.parse(LS.getItemDevice("custom_tokens"));
    let _custom_tokens = [];
    if (custom_tokens) {
      // remove duplicate tokens from storage if added to token list;
      for (let index = 0; index < custom_tokens.length; index++) {
        const _token = custom_tokens[index];
        let tokenExist = tokensFinal.find(t => t.address == _token.address);
        if (!tokenExist) {
          _custom_tokens.push(_token);
        }
      }
      if (custom_tokens.length != _custom_tokens.length) {
        LS.custom_tokens(_custom_tokens);
      }

    }
    result = {
      loading: false,
      base_tokens: tokensFinal,
      custom_tokens: _custom_tokens
    }
  } catch (error) {
    console.log(error)
  }
  return result;
}

/**
 * Initialize the token Pairs
 * @returns 
 */
export const InitializeSwaps = async () => {
  const currentUrl = new URL(window.location.href);
  let result = {
    loading: false,
    timing: Date.now(),
    configs: {
      feeOn: false,
      feeTo: ""
    },
    send: null,
    received: null
  }
  try {
    let tokensService = new TokensService();
    let tokensResult = {};
    switch (import.meta.env.VITE_CHAIN) {
      case "MAINNET":
        tokensResult = await tokensService.getMainnet()
        break;
      case "HARBINGER":
        tokensResult = await tokensService.getHarbinger()
        break;
      default:
        break;
    }

    let tokensFinal = _get(tokensResult, "tokens", []);

    let input = currentUrl.searchParams.get("input")
    let output = currentUrl.searchParams.get("output")
    let inputToken = await getTokenFromList(CONFIG_BASE.tokens.send, input, tokensFinal)
    let outputToken = await getTokenFromList(CONFIG_BASE.tokens.received, output, tokensFinal)

    let ContractProtocol = PeripheryContract();
    let functions = ContractProtocol.functions;
    let _configs = await functions.get_config();
    let _results = _get(_configs, "result", {});

    // result
    result = {
      loading: false,
      timing: Date.now(),
      configs: {
        feeOn: _get(_results, "feeOn", false),
        feeTo: _get(_results, "feeTo", "")
      },
      send: inputToken,
      received: outputToken
    }
  } catch (error) {
    console.log(error)
  }
  return result;
}




/**
 * Receives an address and a token list, checks if the address field is a valid addressand then checks if the address is part of the existingTokens list.
 * If the token is not known, the function will call loadTokendata
 * @param {string} defaultToken
 * @param {string} address 
 * @param {Array} existingTokens 
 * @returns 
 */
const getTokenFromList = async (defaultToken = null, address, existingTokens) => {
  let tokenResult = null
  if (address != null && address != "" && regexBtcAddress(address)) {
    let _result = existingTokens.filter((t) => {
      return t.address == address
    })
    if (_result.length > 0) {
      tokenResult = _result[0]
    } else {
      const result = await loadTokenData(address)
      tokenResult = result
    }
  } else {
    tokenResult = defaultToken
  }
  return tokenResult
}