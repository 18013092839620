import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get as _get } from "lodash";
import { getActiveAdvertisement } from "../../../utils/advertisement";
import { advertisements } from "../../../constants/advertisement"

// components mui
import { Box, Button, List, ListItem, ListItemIcon, ListItemText, ListItemButton, Modal, TextField, Typography } from "@mui/material";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";

// Actions
import { setModalData, setModal } from "../../../redux/actions/modals";
import { setTokenReceived, setTokenSend } from "../../../redux/actions/swap";
import { setAssetOne, setAssetTwo } from "../../../redux/actions/liquidity";
import { setImportAssetOne, setImportAssetTwo } from "../../../redux/actions/positions";

// components
import ModalHeader from "../ModalHeader";
import { advertisementSlots } from "../../../constants/advertisement";

const ModalSelectToken = () => {
  // hooks
  const dispatch = useDispatch();

  // selectors
  const currentModal = useSelector((state) => state.modals.modal);
  const currentModalData = useSelector((state) => state.modals.data);
  // swap
  const tokenSend = useSelector((state) => state.swap.send);
  const tokenReceived = useSelector((state) => state.swap.received);
  // liquidity
  const assetOne = useSelector((state) => state.liquidity.assetOne);
  const assetTwo = useSelector((state) => state.liquidity.assetTwo);
  // positions
  const importAssetOne = useSelector((state) => state.positions.assetOne);
  const importAssetTwo = useSelector((state) => state.positions.assetTwo);
  const base_tokens = useSelector((state) => state.tokens.base_tokens);
  const custom_tokens = useSelector((state) => state.tokens.custom_tokens);

  // states
  const [inputSearch, setInputSearch] = useState(null);
  const [tokens, setTokens] = useState([]);

  // console.log(currentModalData)

  // functions
  const search = (e) => {
    const value = e.target.value
    setInputSearch(value)
    if (value.length > 0) {
      setTokens(base_tokens.concat(custom_tokens).filter((el) => {
        let name = el.name.toLowerCase()
        return name.includes(value.toLowerCase())
      }))
    } else {
      setTokens(base_tokens.concat(custom_tokens))
      setInputSearch(null)
    }
  }
  const closeModal = () => {
    dispatch(setModal(null));
    dispatch(setModalData(null));
  };

  const AdvertisementSlot = () => {
    let ad = getActiveAdvertisement(advertisementSlots.searchbar)
    if (!ad) {
      return null
    }
    if (ad.length > 0) {
      ad = ad[0]
    }
    // take token from standard token list
    let advertisedToken = tokens.filter((t) => {
      return t.address == ad.contract
    })

    if (advertisedToken.length > 0) {
      advertisedToken = advertisedToken[0]
    } else {
      return null
    }
    return (
      <ListItem sx={{ padding: ".1em .5em" }}>
        <ListItemButton onClick={(e) => onSelect(e, advertisedToken)} divider={true} sx={{ backgroundColor: "background.light", "&:hover": { backgroundColor: "background.light" }, borderRadius: "10px" }}>
          <ListItemIcon>
            {
              _get(advertisedToken, "logoURI", "") != "" ?
                <img
                  src={
                    _get(advertisedToken, "logoURI", "")
                  }
                  style={{ width: "40px", height: "40px" }}
                  alt="token"
                />
                :
                <HelpRoundedIcon color="primary" style={{ width: "40px", height: "40px" }}
                  alt="token"
                />
            }
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1" component="p">{_get(advertisedToken, "name", "")}</Typography>} secondary={<Typography color="secondary" variant="body2" component="p">{_get(advertisedToken, "symbol", "")}</Typography>} />
          <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}><Typography color="secondary" variant="body2">ADVERTISEMENT</Typography></div>
        </ListItemButton>
      </ListItem>
    )
  }


  const onSelect = (e, token) => {
    const reduce = _get(currentModalData, "reduce", "");
    if (reduce === "swap" || reduce === "liquidity" || reduce === "position") {
      const value = _get(currentModalData, "value", "");
      switch (value) {
        case "send":
          if (_get(token, "address", "") === _get(tokenReceived, "address", "")) {
            dispatch(setTokenSend(tokenReceived));
            dispatch(setTokenReceived(tokenSend));
          } else {
            dispatch(setTokenSend(token));
          }
          closeModal();
          break;
        case "received":
          if (_get(token, "address", "") === _get(tokenSend, "address", "")) {
            dispatch(setTokenSend(tokenReceived));
            dispatch(setTokenReceived(tokenSend));
          } else {
            dispatch(setTokenReceived(token));
          }
          closeModal();
          break;
        case "assetOne":
          if (_get(token, "address", "") === _get(assetTwo, "address", "")) {
            dispatch(setAssetOne(assetTwo));
            dispatch(setAssetTwo(assetOne));
          } else {
            dispatch(setAssetOne(token));
          }
          closeModal();
          break;
        case "assetTwo":
          if (_get(token, "address", "") === _get(assetOne, "address", "")) {
            dispatch(setAssetOne(assetTwo));
            dispatch(setAssetTwo(assetOne));
          } else {
            dispatch(setAssetTwo(token));
          }
          closeModal();
          break;
        case "importAssetOne":
          if (_get(token, "address", "") === _get(importAssetTwo, "address", "")) {
            dispatch(setImportAssetOne(importAssetTwo));
            dispatch(setImportAssetTwo(importAssetOne));
          } else {
            dispatch(setImportAssetOne(token));
          }
          dispatch(setModal("ImportPosition"));
          break;
        case "importAssetTwo":
          if (_get(token, "address", "") === _get(importAssetOne, "address", "")) {
            dispatch(setImportAssetOne(importAssetTwo));
            dispatch(setImportAssetTwo(importAssetOne));
          } else {
            dispatch(setImportAssetTwo(token));
          }
          dispatch(setModal("ImportPosition"));
          break;
        default:
          break;
      }
      return;
    }
  };
  const updateTokens = () => {
    setTokens(base_tokens.concat(custom_tokens))
  }

  // effects
  useEffect(updateTokens, [currentModal === "SelectToken"])

  return (
    <Modal open={currentModal === "SelectToken"} onClose={closeModal} sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
      <Box sx={{ '&::-webkit-scrollbar': { "borderRadius": "0px 10px 10px 0px" }, width: "100%", maxWidth: "420px", maxHeight: "70vh",  marginY: "20px", bgcolor: "background.paper", borderRadius: "10px", overflowY: "auto", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <ModalHeader title="Select a Token" closeFunction={closeModal} />
        <Box sx={{ p: 1 }}>
          <TextField
            id="outlined-search"
            label="Search name or paste address"
            type="search"
            sx={{
              width: "100%", "& .MuiInputBase-root": {
                color: "text.main", borderRadius: "10px", "& fieldset": {
                  borderColor: "secondary.main",
                }, "&:hover fieldset": {
                  borderColor: "primary.main",
                }
              }
            }}
            defaultValue={inputSearch}
            onChange={search}
            size="small"
          />

        </Box>
        <List sx={{ height: "auto", maxHeight: "800px", overflow: "auto" }}>
          <AdvertisementSlot />
          {tokens.map((token, key) => (
            <ListItem sx={{ padding: ".1em .5em" }} key={key}>
              <ListItemButton onClick={(e) => onSelect(e, token)} divider={false} sx={{ "&:hover": { backgroundColor: "background.light" }, borderRadius: "10px" }}>
                <ListItemIcon>
                  {
                    _get(token, "logoURI", "") != "" ?
                      <img
                        src={
                          _get(token, "logoURI", "")
                        }
                        style={{ width: "40px", height: "40px" }}
                        alt="token"
                      />
                      :
                      <HelpRoundedIcon color="primary" style={{ width: "40px", height: "40px" }}
                        alt="token"
                      />
                  }
                </ListItemIcon>
                <ListItemText primary={<Typography variant="body1" component="p">{_get(token, "name", "")}</Typography>} secondary={<Typography color="secondary" variant="body2" component="p">{_get(token, "symbol", "")}</Typography>} />
              </ListItemButton>
            </ListItem>
          ))}

        </List>
        <Box sx={{ p: 1, justifyContent: "flex-end" }}>
          <Button variant="contained" sx={{ width: "100%", borderRadius: "10px" }} onClick={() => dispatch(setModal("ManageToken"))}>Manage Tokens</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalSelectToken;
