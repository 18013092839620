// this file contains booked advertisement slots
// from and until are date strings in UTC
export const advertisements = [
  {
    "slot": "searchbar",
    "contract": "1KU6cUY3TwYQzTRHakUcviiYmxNepRKkhQ",
    "from": "2024/1/15 18:00:00",
    "until": "2024/1/22 18:00:00"
  }
]

export const advertisementSlots = {
  searchbar: "searchbar",
  banner: "banner"
}