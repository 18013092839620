// eslint-disable-next-line
import MyKoinosWallet from "@roamin/my-koinos-wallet-sdk";
import { Provider } from "koilib";
import * as kondor from "kondor-js";
import { get as _get } from "lodash";
import { useSnackbar } from "notistack";
import React, { Fragment, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Methods } from "@armana/walletconnect-koinos-sdk-js";
import { FormControlLabel, Checkbox, Backdrop, Box, Button, CircularProgress, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Modal, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { initializeWalletConnect, WALLET_CONNECT_METHODS } from "../../../helpers/wallets";

// Actions
import { setModal, setModalData } from "../../../redux/actions/modals";
import { setNetwork } from "../../../redux/actions/settings";
import { initializeSwaps as InitializeSwapsRD } from "../../../redux/actions/swap";
import { initializeTokens as InitializeTokensRD } from "../../../redux/actions/tokens";
import { setConnected, setInstance, setProvider, setSigner, setWallet, setWalletName } from "../../../redux/actions/wallet";

// helpers
import { InitializeSwaps, InitializeTokens } from "../../../helpers/initialize";
// components
import walletconnect from "../../../assets/images/walletconnect.svg";
import KondorLogo from "../../../assets/images/kondor.svg";
import ModalHeader from "./../ModalHeader";
// constants
import { CHAIN_IDS_TO_NAMES, CHAINS_IDS } from "./../../../constants/chains";
import { CONFIG_BASE } from "../../../constants/configs";

const ModalConnect = () => {
  // Dispatch to call actions
  const dispatch = useDispatch();

  // selector
  const currentModal = useSelector(state => state.modals.modal);
  const Snackbar = useSnackbar();

  // states
  const [loading, setLoading] = useState(false);
  const [backdrop, setBackdrop] = useState(false)
  const [, setSelectedWallet] = useState("")

  const [termsAccepted, setTermsAccepted] = useState(false)

  const closeModal = () => {
    dispatch(setModal(null))
    dispatch(setModalData(null))
  }


  // wallets configs
  const resetWalletState = () => {
    dispatch(setSigner(null));
    dispatch(setProvider(null));
    dispatch(setWallet(null));
    dispatch(setWalletName(null));
    dispatch(setInstance(null));
    dispatch(setConnected(false));
    dispatch(setNetwork(null));
  };

  const actionClose = (snackbarId) => (
    <Fragment>
      <Button style={{ color: "white" }} onClick={() => { Snackbar.closeSnackbar(snackbarId) }}>
        Close
      </Button>
    </Fragment>
  );

  const closeBackdrop = () => {
    setBackdrop(false)
  }

  const KondorWallet = () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      let timeOut = setTimeout(() => reject("Error Connect"), 1000 * 60)
      if (kondor) {
        try {
          closeModal()
          // use default provider
          let provider = new Provider([import.meta.env.VITE_RPC]);
          let wallet = await kondor.getAccounts();
          let signer = await kondor.getSigner(_get(wallet, "[0].address", ""), { providerPrepareTransaction: provider });
          let chain_id = await kondor.provider.getChainId();
          wallet.object = null;
          // set connection
          dispatch(setSigner(signer));
          dispatch(setProvider(provider));
          dispatch(setWallet(wallet));
          dispatch(setWalletName("kondor"));
          dispatch(setConnected(true));
          dispatch(setModal(null));
          dispatch(setModalData(null));
          dispatch(setInstance(null));
          // check chain
          if (CHAIN_IDS_TO_NAMES[chain_id] == undefined) {
            chain_id = CHAINS_IDS.UNSUPPORTED
          }
          dispatch(setNetwork(chain_id));
          // load base
          let swaps = await InitializeSwaps(chain_id);
          dispatch(InitializeSwapsRD(swaps));
          let tokens = await InitializeTokens(chain_id);
          dispatch(InitializeTokensRD(tokens));
        } catch (e) {
          console.log(e)
          resetWalletState()
        }
        if (timeOut) { clearTimeout(timeOut); }
        resolve()
      }
    })
  }

  const checkPopUps = () => {
    var newWin = open("https://koindx.com");
    if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
      return false
    }
    return true
  }

  const MyKW = () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      let timeOut = setTimeout(() => reject("Error, cannot Connect"), 1000 * 60);
      const walletConnectorUrl = "https://mykw.vercel.app/embed/wallet-connector"
      try {
        const mkw = new MyKoinosWallet(walletConnectorUrl)
        const connected = await mkw.connect()
        if (connected) {
          closeModal()
          // request permissions to access some of the My Koinos Wallet APIs
          mkw.requestPermissions({
            "accounts": ["getAccounts"],
            "provider": ["getChainId", "readContract", "wait", "getAccountRc", "getTransactionsById"],
            "signer": ["prepareTransaction", "signMessage", "signAndSendTransaction"]
          }).then(async () => {
            // get data from connected wallet
            let wallet = await mkw.getAccounts()
            wallet.name = "mkw"
            wallet.object = mkw;
            const provider = await mkw.getProvider()
            const signer = await mkw.getSigner(_get(wallet, "[0].address", ""));
            let chain_id = await provider.getChainId();

            // set connection
            dispatch(setSigner(signer));
            dispatch(setProvider(provider));
            dispatch(setWallet(wallet));
            dispatch(setWalletName("mkw"));
            dispatch(setConnected(connected));
            dispatch(setModal(null));
            dispatch(setModalData(null));
            dispatch(setInstance(mkw));


            // check chain
            if (CHAIN_IDS_TO_NAMES[chain_id] == undefined) {
              chain_id = CHAINS_IDS.UNSUPPORTED;
            }
            dispatch(setNetwork(chain_id));
          })

        } else {
          Snackbar.enqueueSnackbar(<span><Typography variant="h6">MKW Could not connect - Check that cross-site cookies are activated</Typography></span>, {
            variant: "error",
            persist: false,
            action: actionClose,
          })
          // throw new Error("MKW could not connect")
        }
      }
      catch (e) {
        // if user declines mkw access "request was cancelled" will be returned
        if (e !== "request was cancelled") {
          Snackbar.enqueueSnackbar(<span><Typography variant="h6">MKW Could not connect</Typography></span>, {
            variant: "error",
            persist: false,
            action: actionClose,
          })
        }
        resetWalletState()
      }

      if (timeOut) {
        clearTimeout(timeOut);
      }
      resolve();
    })
  }

  const WC = () => {
    return new Promise((resolve, reject) => {
      // create WalletConnectKoinos
      const walletConnectKoinos = initializeWalletConnect();
      // initiate connection with a wallet
      try {
        walletConnectKoinos.connect(
          [CONFIG_BASE.wallet.walletconnect_network_id],
          [...WALLET_CONNECT_METHODS]
        ).then((accounts) => {

          if (accounts) {
            const provider = new Provider([import.meta.env.VITE_RPC]);
            const signer = walletConnectKoinos.getSigner(accounts[0]);
            const connected = accounts ? true : false;
            dispatch(setSigner(signer));
            dispatch(setProvider(provider));
            dispatch(setWallet([{ name: "walletconnect", address: accounts[0], signers: [signer], object: walletConnectKoinos }]));
            dispatch(setWalletName("walletconnect"));
            dispatch(setInstance(walletConnectKoinos));
            dispatch(setModal(null));
            dispatch(setModalData(null));
            dispatch(setConnected(connected));
            resolve(true);
          }
        }).catch((e) => {
          console.log(e)
        })
      } catch (e) {
        setBackdrop(false)
        setSelectedWallet("")
        setLoading(false)
        resetWalletState()
        walletConnectKoinos.disconnect()
        reject(e);
      }
    })
  }

  // methods
  const selectWallet = async (typed) => {
    setLoading(true);
    try {
      switch (typed) {
        case "kondor-wallet":
          setSelectedWallet("kondor")
          await KondorWallet()
          break;
        case "mkw-wallet":
          setSelectedWallet("mkw")
          await MyKW()
          break;
        case "wallet-connect":
          setSelectedWallet("walletconnect")
          setBackdrop(true)
          closeModal()
          setLoading(false)
          await WC()
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e)
      Snackbar.enqueueSnackbar(<span><Typography variant="h6">Could not connect to wallet</Typography></span>, {
        variant: "error",
        persist: false,
        action: actionClose,
      })
    }
    setLoading(false);
  }

  const StyledGrayscaleWrapper = styled(Box)(({ accepted }) => ({
    filter: accepted == "true" ? "grayscale(0)" : "grayscale(1)"
  }));

  const AcceptTerms = () => {
    return (
      <Box sx={{ padding: "20px 0", }}>
        <Typography variant="body1" component="p">1. Accept <Link underline="none" target={"_blank"} href="https://koindx.com/terms-of-use">Terms</Link> and <Link underline="none" href="https://koindx.com/privacy" target={"_blank"}>Privacy</Link> </Typography>
        <div style={{ "paddingLeft": "1em" }}>
          <FormControlLabel control={<Checkbox
            checked={termsAccepted}
            sx={{ color: "text.main", }}
            onChange={() => setTermsAccepted(!termsAccepted)}
            inputProps={{
              "aria-label": "Terms and Privacy consent Checkbox",
            }}
          />} label="I read and accept" />
        </div>
      </Box>
    )
  }

  const ConnectWalletContent = () => {
    return (
      <Box>
        <AcceptTerms />
        <SelectWallet />
      </Box>
    )
  }

  const SelectWallet = () => (
    <div style={{ width: "100%", height: "100%" }}>
      <StyledGrayscaleWrapper accepted={termsAccepted.toString()}>
        <Box sx={{ width: "100%" }}>
          <Typography variant="body1" component="p">2. Select Wallet</Typography>
          <List sx={{ flexWrap: "wrap", display: { xs: "block", sm: "flex" }, flexFlow: { xs: "column", sm: "wrap" } }}>
            {isMobile ?
              null :
              <ListItem sx={{ paddingX: "4px" }} >
                <ListItemButton sx={{ height: "60px", padding: ".75em", backgroundColor: "background.light", borderRadius: "10px", "&:hover": { backgroundColor: "background.default" } }} disabled={!termsAccepted} variant="outlined" onClick={() => selectWallet("kondor-wallet")}>
                  <ListItemIcon>
                    <img src={KondorLogo} style={{ width: "40px", height: "40px" }} alt="kondor wallet" />
                  </ListItemIcon>
                  <ListItemText primary="Kondor Wallet" />
                </ListItemButton>
              </ListItem>
            }
            <ListItem sx={{ paddingX: "4px" }} >
              <ListItemButton sx={{ height: "60px", padding: ".75em", backgroundColor: "background.light", borderRadius: "10px", "&:hover": { backgroundColor: "background.default" } }} disabled={!termsAccepted} variant="outlined" onClick={() => selectWallet("mkw-wallet")}>
                <ListItemIcon>
                  <img
                    src="mkw-logo.png"
                    style={{ width: "40px", height: "40px" }} alt="My Koinos Wallet wallet logo" />
                </ListItemIcon>
                <ListItemText primary="My Koinos Wallet" sx={{}} />
              </ListItemButton>
            </ListItem>
            <ListItem sx={{ paddingX: "4px" }} >
              <ListItemButton sx={{ height: "60px", padding: ".75em", backgroundColor: "background.light", borderRadius: "10px", "&:hover": { backgroundColor: "background.default" } }} disabled={!termsAccepted} variant="outlined" onClick={() => selectWallet("wallet-connect")} >
                <ListItemIcon>
                  <img
                    src={walletconnect}
                    style={{ width: "40px", height: "40px" }} alt="Walletconnect logo" />
                </ListItemIcon>
                <ListItemText primary="Wallet Connect" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </StyledGrayscaleWrapper>
    </div>
  )
  const LoadingWallet = () => (
    <div style={{ width: "90%", height: "100%", justifyContent: "center", alignItems: "center", display: "flex", margin: "auto" }}>
      <Box sx={{ backgroundColor: "background.paper", width: "100%", padding: "20px 10px" }}>
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress sx={{ marginBottom: "20px" }} color="secondary" />
        </Box>
        <Typography variant="subtitle1" component={"p"}>Popups {checkPopUps ? "allowed" : "forbidden"}</Typography>
        <Typography variant="body1" component="p" sx={{ marginBottom: "20px" }}>If you do not have Kondor or My Koinos Wallet installed you can do so by clicking one of the following buttons.</Typography>
        {isMobile ?
          null :
          <Button variant="contained" sx={{ width: "100%" }} href="https://chrome.google.com/webstore/detail/kondor/ghipkefkpgkladckmlmdnadmcchefhjl" target="_blank">
            Install Kondor Wallet
          </Button>
        }
        <Button variant="contained" sx={{ marginTop: "10px", width: "100%" }} component={Link} href="https://mykw.vercel.app" target="_blank">
          Use My Koinos Wallet
        </Button>

        <Button size="large" color="error" variant="outlined" sx={{ marginTop: "10px", width: "100%" }} onClick={() => {
          setLoading(false)
          resetWalletState()
        }}>
          cancel
        </Button>
      </Box>
    </div>
  )

  return (
    <>
      {
        loading && setSelectedWallet == "walletconnect" ?

          <Backdrop
            sx={{ color: "#fff", zIndex: () => 0 }}
            open={backdrop}
            onClick={closeBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          :

          <Modal
            open={currentModal === "Connect"}
            onClose={closeModal}
            sx={{ alignItems: "center", justifyContent: "center", display: "flex", marginY: "auto" }}
          >
            <Box sx={{
              height: "auto",
              width: "100%",
              maxWidth: "500px",
              bgcolor: "background.paper",
              borderRadius: "10px",
              overflowY: "hidden"
            }}>
              <ModalHeader title={"Connect"} closeFunction={closeModal} backArrow={null} />
              <Box sx={{ paddingX: "15px", marginY: "auto" }}>
                <div style={{ minWidth: "sm", width: "100%", maxWidth: "520px", justifyContent: "center", alignItems: "center" }}>
                  <Typography variant="body1" component="span">
                    {loading ? <LoadingWallet /> : <ConnectWalletContent />}
                  </Typography>
                </div>
              </Box>
            </Box>
          </Modal>
      }
    </>
  )
}

export default ModalConnect;
