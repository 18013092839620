import { ChainIds } from "@armana/walletconnect-koinos-sdk-js";
const APP_CHAIN = import.meta.env.VITE_CHAIN;

let config = {
  ["MAINNET"]: {
    explorer: [
      {
        name: "Koiner",
        url: "https://koiner.app/blocks"
      }, {
        name: "Koinosblocks",
        url: "https://koinosblocks.com/block"
      }
    ],
    api: {
      url: import.meta.env.VITE_BACKEND_API_URL,
      key: import.meta.env.VITE_BACKEND_API_KEY
    },
    chain: {
      name: "Mainnet",
      id: "EiBZK_GGVP0H_fXVAM3j6EAuz3-B-l3ejxRSewi7qIBfSA==",
      nameservice: [
        "koin", "vhp"
      ],
      contracts: {
        nameservice: "19WxDJ9Kcvx4VqQFkpwVmwVEy1hMuwXtQE"
      }
    },
    contracts: {
      ido: {
        address: "",
        launched: false,
      },
      koindx: {
        address: "",
        launched: false
      },
      skoindx: {
        address: "",
        launched: false
      },
      governance: {
        address: "",
        launched: false
      },
      periphery: {
        address: "17e1q6Fh5RgnuA8K7v4KvXXH4k9qHgsT5s",
        launched: true
      }
    },
    tokens: {
      send: {
        name: "Koin",
        symbol: "KOIN",
        description: "Native token of Koinos.",
        decimals: "8",
        logoURI: "https://raw.githubusercontent.com/koindx/token-list/main/src/images/mainnet/koin.png",
        address: "koin",
        allowance: false,
        token_website: "https://koinos.io",
        coingecko_id: "",
        coinmarketcap_id: "",
      },
      received: {
        name: "Tether USD",
        symbol: "USDT",
        description: "Wrapped Tether USD via chainge finance.",
        decimals: "8",
        logoURI: "https://raw.githubusercontent.com/koindx/token-list/main/src/images/mainnet/19WrWze3XAoMa3Mwqys4rJMP6emZX2wfpH.png",
        address: "19WrWze3XAoMa3Mwqys4rJMP6emZX2wfpH",
        allowance: true,
        token_website: "",
        coingecko_id: "",
        coinmarketcap_id: ""
      }
    },
    wallet: {
      walletconnect_network_id: ChainIds.Mainnet,
    }
  },
  ["HARBINGER"]: {
    explorer: [
      {
        name: "Koinosblocks",
        url: "https://harbinger.koinosblocks.com/block"
      },
      {
        name: "Koiner",
        url: "https://koiner.app/blocks"
      }
    ],
    api: {
      url: import.meta.env.VITE_BACKEND_API_URL,
      key: import.meta.env.VITE_BACKEND_API_KEY
    },
    chain: {
      name: "Harbinger",
      id: "EiBncD4pKRIQWco_WRqo5Q-xnXR7JuO3PtZv983mKdKHSQ==",
      nameservice: [
        "koin", "vhp"
      ],
      contracts: {
        nameservice: "13NQnca5chwpKm4ebHbvgvJmXrsSCTayDJ"
      }
    },
    contracts: {
      ido: {
        address: "",
        launched: false,
      },
      koindx: {
        address: "",
        launched: false
      },
      skoindx: {
        address: "",
        launched: false
      },
      periphery: {
        address: "18MV8beHrgV78JpHxTXrvPfSmP2kWy7DVk",
        launched: true
      }
    },
    tokens: {
      send: {
        name: "tKoin",
        symbol: "TKOIN",
        description: "Native token of Koinos.",
        decimals: "8",
        logoURI: "https://raw.githubusercontent.com/koindx/token-list/main/src/images/harbinger/1FaSvLjQJsCJKq5ybmGsMMQs8RQYyVv8ju.png",
        address: "koin",
        allowance: false,
        token_website: "https://koinos.io",
        coingecko_id: "",
        coinmarketcap_id: "",
      },
      received: {
        name: "Virtual Hash Power",
        symbol: "VHP",
        description: "The fuel for producing blocks on Koinos.",
        decimals: "8",
        logoURI: "https://raw.githubusercontent.com/koindx/token-list/main/src/images/mainnet/vhp.png",
        address: "vhp",
        allowance: false,
        token_website: "https://koinos.io",
        coingecko_id: "",
        coinmarketcap_id: ""
      }
    },
    wallet: {
      //walletconnect_network_id: "koinos:EiBncD4pKRIQWco_WRqo5Q-xnXR7JuO3PtZv983mKdKHSQ=="
      walletconnect_network_id: ChainIds.Harbinger
    }
  },
};

export const CONFIG_BASE = config[APP_CHAIN];
