import React from "react";
import { createRoot } from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { Provider as ReactProvider } from "react-redux"
import * as Sentry from "@sentry/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
const container = document.getElementById("root");
import generateStore from "./redux/index";
const root = createRoot(container);
const store = generateStore();

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DNS,
  environment: import.meta.env.VITE_CHAIN === "MAINNET" ? "production" : "staging",
  integrations: [
    new Sentry.BrowserTracing({
      // Set "tracePropagationTargets" to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost"],
    }),
    new Sentry.Replay(),
  ],
  beforeSend: (event) => {
    if (window.location.hostname === "localhost") {
      return null;
    }
    return event;
  },
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


root.render(
  <React.StrictMode>
    <ReactProvider store={store}>
      <CssBaseline />
      <App />
    </ReactProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();