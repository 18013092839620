import React from "react"
import { Box, Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { dropdownRoutes } from "../../constants/routes"
export const DropdownNav = () => {
  const navigate = useNavigate()

  const prepareRoutes = () => {
    let routeList = []
    for (let route of dropdownRoutes) {
      routeList.push(<Button color="primary" size={"large"} target={"_blank"} key={route.index} sx={{ width: "100%", marginY: "0.5em", color: "white", "&:hover": { color: "primary.main" } }} href={route.external ? route.route : null} onClick={() => { (route.external ? null : navigate(route.route)) }}>
        {route.label}
      </Button>)
    }
    return routeList
  }
  return (
    <Box sx={{ marginTop: ".5em", width: { xs: "200px" }, padding: "10px", borderRadius: "10px", backgroundColor: "background.paper", display: "flex", flexDirection: "column" }}>
      {
        prepareRoutes()
      }
    </Box>
  )
}