import { SET_TOKEN_SEND, SET_TOKEN_RECEIVED, INITIALIZE_SWAP, SET_SWAPS_TIMING } from "../constants"

// Actions
export const setTokenSend = (data) => ({
  type: SET_TOKEN_SEND,
  payload: data
})

export const setTokenReceived = (data) => ({
  type: SET_TOKEN_RECEIVED,
  payload: data
})

export const initializeSwaps = (data) => ({
  type: INITIALIZE_SWAP,
  payload: data
})

export const setSwapsTiming = (data) => ({
  type: SET_SWAPS_TIMING,
  payload: data
})

