import { useEffect, useState } from "react";
import { get as _get } from "lodash"
import { BaseController } from "./BaseController";
import { LiquidityPositionController } from "./LiquidityPositionController";


class PairController extends BaseController {
  static tableName = 'Pair';

  static async getPairsById(pairIds) {
    let { data: Pair, error } = await this.getWithFilter([{ method: 'in', column: 'id', value: pairIds }])
    return { Pair, error }
  }
  static async getPairsByIdWithToken(pairIds) {
    //let { data: Pair, error } = await this.getWithFilter([{ method: 'in', column: 'id', value: pairIds }])
    let { data: Pair, error } = await this.supabase.from(this.tableName).select(`
    id,
    protocolId,
    token0: token0Id(id, symbol, name, decimals),
    token1: token1Id(id, symbol, name, decimals),
    reserve0,
    reserve1,
    totalSupply,
    reserveKOIN,
    reserveUSD,
    token0Price,
    token1Price,
    volumeToken0,
    volumeToken1,
    txCount,
    liquidityProviderCount
    `).in('id', pairIds)
    return { Pair, error }
  }


  static async getPairsByToken(token) {
    let { data: Pair, error } = await this.supabase.from(this.tableName).select('*').or(`token0Id.eq.${token},token1Id.eq.${token}`)
    return { Pair, error }
  }

  static async getPairByTokens(token0, token1) {
    let { data: Pair, error } = await this.supabase.from(this.tableName).select('*').in('token0Id', [token0, token1])
    let match = null
    if (Pair) {
      Pair.forEach(el => {
        if ((el.token0Id == token0 || el.token0Id == token1) && (el.token1Id == token0 || el.token1Id == token1)) {
          match = el
        }
      });
    }
    return { match, error }
  }
}


export { PairController }