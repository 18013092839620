/* eslint-disable */
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { InitializeTokens, InitializeSwaps } from "./helpers/initialize";
import { get as _get } from "lodash"
import "./App.css";

// actions
import { initializeTokens as InitializeTokensRD } from "./redux/actions/tokens";

// pages
import Layout from "./components/Layout";
import Liquidity from "./pages/Liquidity/Liquidity";
import AddLiquidity from "./pages/Liquidity/AddLiquidity";
import RemoveLiquidity from "./pages/Liquidity/RemoveLiquidity";
import Swap from "./pages/Swap";
import ComingSoon from "./pages/ComingSoon";

import NotFound from "./pages/NotFound"

function AppRouter() {
  const dispatch = useDispatch();

  useEffect(() => {
    async function load() {
      // tokens
      let tokens = await InitializeTokens();
      dispatch(InitializeTokensRD(tokens));
    }
    load();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path='*' element={<NotFound />} />
          <Route path="/" element={<Navigate replace to="/swap" />} />
          <Route path="/liquidity">
            <Route index element={<Liquidity />} />
            <Route path="add">
              <Route index element={<AddLiquidity />} />
              <Route path=":token_a/:token_b" element={<AddLiquidity />} />
            </Route>
            <Route path="remove">
              <Route index element={<RemoveLiquidity />} />
              <Route path=":token_a/:token_b" element={<RemoveLiquidity />} />
            </Route>
          </Route>
          <Route path="/swap" element={<Swap />} />
          <Route path="/dashboard" element={<ComingSoon />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default AppRouter;