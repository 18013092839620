/* eslint-disable */

/**
 * NumberPattern receives a String, which will be validated and transformed,
 * and a callback function which will receive the validated string
 * The string can only include numbers and one dot for the decimal places
 * 
 * @example valid String: 3958325.3523
 * 
 * @param {String} value 
 * @param {function} targetCB 
 */
export const numberPattern = (value, targetCB) => {
  // First, remove any characters that are not digits or period
  let filtered = String(value).replace(/[^\d.]/g, '');

  // Then split by period and reconstruct to ensure only one period is present
  let parts = filtered.split('.');
  let validated = parts[0] + (parts.length > 1 ? '.' + parts.slice(1).join('') : '');

  targetCB(validated);
};

export const regexBtcAddress = (value) => {
  const regexPattern = /^(?:[13]{1}[a-km-zA-HJ-NP-Z1-9]{26,33}|bc1[a-z0-9]{39,59})$/g
  return new RegExp(regexPattern).test(value)
}