import { useEffect, useState } from "react";
import { get as _get } from "lodash"
import { BaseController } from "./BaseController";
import { PairController } from "./PairController";


class LiquidityPositionController extends BaseController {
  static tableName = 'LiquidityPosition';

  static async getLiquidityPositionByPairId(id) {
    let { data: LiquidityPosition, error } = await this.getWithFilter({ method: 'eq', column: 'pairId', value: id })
    return { LiquidityPosition, error }
  }

  static async getLiquidityPositionByUserId(userId) {
    let { data: LiquidityPosition, error } = await this.getWithFilter([{ method: 'eq', column: 'userId', value: userId },
    { method: 'gt', column: 'liquidityTokenBalance', value: 0 }
    ])
    return { LiquidityPosition, error }
  }

  static async getData(userId) {
    const data = await this.getLiquidityPositionByUserId(userId)
    if (data && data.length > 0) {
      let ids = []
      data.foreach(el => {
        ids.push(el.pairId)
      })
      const pairData = await PairController.getPairsById(ids)
    }
  }
}


export { LiquidityPositionController }