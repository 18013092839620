import { SET_CUSTOM_TOKENS, INITIALIZE_TOKENS } from "../constants"

export const setCustomTokenData = (data) => ({
  type: SET_CUSTOM_TOKENS,
  payload: data
})

export const initializeTokens = (data) => ({
  type: INITIALIZE_TOKENS,
  payload: data
})
