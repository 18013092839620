import React from "react";
import { ThemeProvider, Link, Typography, CssBaseline, Container, IconButton, useMediaQuery, useTheme, Box } from "@mui/material";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { get as _get } from "lodash";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { CONFIG_BASE } from "../../constants/configs";

// icons
import CloseIcon from "@mui/icons-material/Close";

// components
import Header from "../Header";
import Modals from "../Modals";

// themes
import themeOptions from "../../theme/main";
import { BottomNav } from "../BottomNav";

const Layout = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const design = responsiveFontSizes(createTheme(themeOptions));

  const dexdataSelector = useSelector((state) => state.dexdata);
  const settingSelector = useSelector((state) => state.settings);
  const useapi = _get(settingSelector, "useapi", true)

  const notistackRef = React.createRef();
  const SnackbarActions = (key) => (
    <IconButton component="span" onClick={() => notistackRef.current.closeSnackbar(key)}>
      <CloseIcon sx={{ color: "text.main" }} />
    </IconButton>
  );

  return (
    <ThemeProvider theme={createTheme(design)}>
      <CssBaseline />
      <SnackbarProvider
        ref={notistackRef}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={2000}
        action={(key) => SnackbarActions(key)}
      >
        <Box sx={{ backgroundColor: theme.palette.background.main, minHeight: "100vh" }}>
          <Header />
          <Container
            maxWidth="false"
            sx={{ minWidth: "calc(100vw - 20px)" }}
          >
            <Outlet />
            <Modals />
          </Container>
          {_get(dexdataSelector, 'blockheight', null) != null && useapi === true ?
            <Link component={"a"} href={`${CONFIG_BASE.explorer.find((el) => el.name.toLowerCase() === "koinosblocks").url}/${_get(dexdataSelector, 'blockheight', 0)}`} target="_blank" title="Inspect block in explorer" underline={"none"} sx={{ paddingLeft: "10px", display: { xs: "none", sm: "inline-block" }, position: "fixed", bottom: { xs: "70px", sm: "10px" }, right: "25px" }}>
              <Typography sx={{ color: "#21c187" }} component={"span"}>
                {`●`}
              </Typography>
              <Typography color="secondary.light" component={"span"} sx={{ fontSize: "0.8rem !important", paddingLeft: "4px" }}>
                {`${_get(dexdataSelector, 'blockheight', 0)}`}
              </Typography>
            </Link> :
            ""
          }
          {
            matches ?
              <BottomNav sx={{ width: "100%", position: "fixed", bottom: "0px", overflow: "hidden" }} />
              : null
          }
        </Box>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
export default Layout;
