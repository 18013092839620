export const routes = [
  { index: 0, route: "/swap", paths: ["/swap"], label: "swap" },
  { index: 1, route: "/liquidity", paths: ["/liquidity", "/add"], label: "liquidity" },
  // { index: 2, route: "/dashboard", paths: ["/dashboard"], label: "dashboard" },
];

export const dropdownRoutes = [
  { index: 0, route: "https://docs.koindx.com", external: true, label: "docs" },
  { index: 1, route: "https://koindx.com/terms-of-use", external: true, label: "Terms of Use" },
  { index: 2, route: "https://koindx.com/privacy", external: true, label: "Privacy" },
]
// { index: 0, route: "https://ido.koindx.com", external: true, label: "IDO" },