import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { get as _get } from "lodash";
import { utils as koilibUtils } from "koilib";
import { BigNumber } from "bignumber.js";
import { CONFIG_BASE } from "./../../constants/configs";

// components
import { Avatar, Box, Button, InputBase, Typography, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";

// utils
import { numberPattern } from "./../../utils/regex";

// contracts
import { TokenContract, NameServiceContract } from "./../../helpers/contracts";

const TokenNotExist = ({ onSelect }) => {

  // selector
  const networkSelector = useSelector(state => state.settings.network);

  return (
    <Button disabled={networkSelector == "UNSUPPORTED"} onClick={onSelect}>
      <Typography variant="body1" component="span">
        Select a token
      </Typography>
    </Button>
  );
};

const TokenExist = ({ token, onSelect }) => {
  const symbol = _get(token, "symbol", "");
  return (
    <Button sx={{ height: "36px" }} onClick={() => onSelect()}>
      {
        _get(token, "logoURI", "") != "" ?
          <Avatar sx={{ width: 30, height: 30, marginRight: "6px" }} alt={_get(token, "symbol", "token")} src={_get(token, "logoURI", "")} />
          :
          <HelpRoundedIcon color="primary" style={{ marginRight: "6px", width: "30px", height: "30px", borderRadius: "50%" }}
            alt="token"
          />
      }
      <Typography variant="h6" sx={{ color: "text.main", display: "block" }}>
        {symbol}
      </Typography>
    </Button>
  );
};

const copyTokenAddress = async (token) => {
  try {
    await navigator.clipboard.writeText(token.address);
  } catch (err) {
    console.log(err);
  }
};

let address = "";

const TokenInputPanel = ({ token, inputVal, onSelect, onBalance, onInput }) => {
  // selectors
  const walletSelector = useSelector((state) => state.wallet);
  const swapTimingSelector = useSelector((state) => state.swap.timing);
  const [balance, setBalance] = useState("loading...");
  const [loadingBalance, setLoadingBalance] = useState(true);

  // functions
  const loadBalance = async () => {
    let wallet = _get(walletSelector, "wallet", null);
    if (wallet && token) {
      try {
        // prepared contract
        let provider = _get(walletSelector, "provider", null);
        let signer = _get(walletSelector, "signer", null);
        let address_token = _get(token, "address", "");
        if(_get(CONFIG_BASE, 'chain.nameservice', []).indexOf(address_token) != -1 && _get(CONFIG_BASE, 'chain.contracts.nameservice', "") != "") {
          let ns = NameServiceContract(provider, signer)
          let result = await ns.functions.get_address({ name: address_token });
          address_token = _get(result, 'result.value.address', '')
        }
        let contract = TokenContract(address_token, provider, signer);

        // prepared contract
        let address = _get(wallet, "[0].address", "");
        let functions = contract.functions;
        let { result } = await functions.balanceOf({ owner: address });
        let _amount = token.decimals != "0" ? koilibUtils.formatUnits(_get(result, "value", 0), token.decimals) : _get(result, "value", 0);
        onBalance(_get(result, "value", 0));
        setBalance(`${_amount}`);
        setLoadingBalance(false)
      } catch (error) {
        console.log("error:", error)
        console.log(_get(token, "address", ""))
      }
    } else {
      setBalance("loading...");
    }
  };

  useEffect(() => {
    loadBalance(0)
  }, [swapTimingSelector])

  if (address != _get(token, "address", "")) {
    address = _get(token, "address", "");
    loadBalance(0)
  }

  return (
    <Box sx={{ marginBottom: "10px" }}>
      <Box paddingY={"10px"} paddingX={"15px"} marginTop={"1em"} borderRadius={"10px"} sx={{ backgroundColor: "background.light" }}>
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <InputBase placeholder="0" type="text" value={inputVal} sx={{ fontSize: "1.25em !important", color: "text.main", ml: 1, flex: 1, height: "60px" }} onChange={(e) => numberPattern(e.currentTarget.value, onInput)} />
          {!token ? <TokenNotExist onSelect={() => onSelect()} /> : <TokenExist token={token} onSelect={onSelect} />}
          {token !== null ? (
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => {
                copyTokenAddress(token);
              }}
            >
              <ContentCopyIcon fontSize="inherit" sx={{ color: "text.grey2" }} />
            </IconButton>
          ) : (
            ""
          )}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
          <Box>
            {
              token && _get(walletSelector, "wallet", null) ? <Typography variant="body1" component={"span"} sx={{ color: "text.grey2", marginTop: "5px" }}>Balance: {loadingBalance ? "loading..." : balance}</Typography> : null
            }
          </Box>

          {_get(walletSelector, "wallet", null) && token ? (


            <Box sx={{ display: "flex", justifyContent: "right", padding: "0" }}>
              <div style={{ marginTop: "8px", marginRight: "0" }}>
                <Button
                  variant="dark-grey-contained"
                  size="small"
                  sx={{ marginRight: "5px", paddingX: "0px" }}
                  onClick={() => {
                    numberPattern(balance, onInput);
                  }}
                >
                  <Typography variant="h6" sx={{ color: "text.grey2" }}>
                    MAX
                  </Typography>
                </Button>
                <Button
                  variant="dark-grey-contained"
                  sx={{ padding: "10px", display: { xs: "none", sm: "inline-flex" } }}
                  size="small"
                  onClick={() => {
                    // numberPattern(balance, halfButtonClick);
                    numberPattern(balance, (b) => {
                      let fullAmount = koilibUtils.parseUnits(b, token.decimals);
                      let _amount = new BigNumber(fullAmount).dividedBy(2).toFixed(0, 1);
                      let value = token.decimals != "0" ? koilibUtils.formatUnits(_amount, token.decimals) : _amount;
                      onInput(value);
                    });
                  }}
                >
                  <Typography variant="h6" sx={{ color: "text.grey2" }}>
                    HALF
                  </Typography>
                </Button>
              </div>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(TokenInputPanel);
