import { createClient } from "@supabase/supabase-js";
import { CONFIG_BASE } from "../constants/configs";
/**
 * Class BaseController
 */
class BaseController {
  static tableName;
  // Supabase-Client init
  static supabase = createClient(CONFIG_BASE.api.url, CONFIG_BASE.api.key);

  /**
   * getAll returns all elements of the table (paginated).
   * @param {int} rangeStart 
   * @param {int} rangeEnd 
   * @returns 
   */
  static async getAll(rangeStart = 0, rangeEnd = 9) {
    let { data, error } = await this.supabase
      .from(this.tableName)
      .select('*')
      .range(rangeStart, rangeEnd);
    return { data, error };
  }

  /**
   * getById returns an entry by his id.
   * @param {string} id 
   * @returns 
   */
  static async getById(id) {
    let { data, error } = await this.supabase
      .from(this.tableName)
      .select('*')
      .eq('id', id);
    return { data, error };
  }

  /**
   * 
   * getWithFilter returns rows that match the filter.
   * Filter example:   { method: 'eq', column: 'someColumn', value: 'Equal to' },
   *
   * @param {Object} filterOptions 
   * @returns 
   */
  static async getWithFilter(filterOptions) {
    let query = this.supabase.from(this.tableName).select("*");

    filterOptions.forEach(filter => {
      const { method, column, value } = filter;
      query = query[method](column, value);
    });

    let { data, error } = await query;
    return { data, error };
  }
}

export { BaseController }