/* eslint-disable */
import { BigNumber } from "bignumber.js";
import { utils as koilibUtils } from "koilib";
import { get as _get } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { InitializeSwaps } from "../helpers/initialize";
import { initializeSwaps as InitializeSwapsRD } from "../redux/actions/swap";

// utils
import { maxDecimal } from "../utils/token";
import { waitTransation } from "../utils/transactions";

// components mui
import { Alert, Box, Button, Tooltip, Card, CardContent, CardHeader, CircularProgress, Divider, IconButton, Typography } from '@mui/material';

// icons
import HistoryIcon from "@mui/icons-material/History";
import SettingsIcon from "@mui/icons-material/Settings";
import SwapVerticalCircleIcon from "@mui/icons-material/SwapVerticalCircle";

// components
import Maintenance from "../components/Maintenance";
import TokenInputPanel from "../components/TokenInputPanel";

// Actions
import { setModal, setModalData } from "../redux/actions/modals";
import { setSwapsTiming, setTokenReceived, setTokenSend } from "../redux/actions/swap";

// helpers
import { calculateAmountsIn, calculateAmountsOut, calculateDefaultMana } from "../helpers/calculate";

// constants
import { CONFIG_BASE } from "../constants/configs";

// contracts
import { PeripheryContract, CoreContract, TokenContract, NameServiceContract } from "../helpers/contracts";

const SwapPage = (props) => {
  // Dispatch to call actions
  const dispatch = useDispatch();
  const Snackbar = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();

  // selectors
  const networkSelector = useSelector(state => state.settings.network);
  const swapSelector = useSelector(state => state.swap);
  const walletSelector = useSelector(state => state.wallet);
  const settingsSelector = useSelector(state => state.settings);

  // references
  const t_reserve = useRef(null); // timer get reserves

  // state
  const [balanceSend, setBalanceSend] = useState("loading")
  const [, setBalanceReceived] = useState("loading")
  const [inputSend, setInputSend] = useState(0)
  const [inputReceived, setInputReceived] = useState(0)
  const [debounceIn, setDebounceIn] = useState(null)
  const [debounceOut, setDebounceOut] = useState(null)
  const [exchangeRate, setExchangeRate] = useState("0")
  const [priceImpact, setPriceImpact] = useState("0")
  const [feeImpact, setFeeImpact] = useState("0")

  const [loading, setLoading] = useState(false);
  const [typeSwap, setTypeSwap] = useState(null);
  const [routerSwap, setRouterSwap] = useState([])
  const [routerReserves, setRouterReserves] = useState({})
  const [routerErrors, setRouterErrors] = useState(null)
  const [reserve, setReserve] = useState(null)

  // variables
  const tokenSend = _get(swapSelector, "send", null);
  const tokenReceived = _get(swapSelector, "received", null);
  const slippage = _get(settingsSelector, "slippage", "0.1");
  const provider = _get(walletSelector, "provider", null)
  const signer = _get(walletSelector, "signer", null)

  // constants
  const refreshTimingReserve = 30 * 1000;
  const debounceTiming = 800;

  const openModalSelectToken = (data) => {
    dispatch(setModalData(data))
    dispatch(setModal("SelectToken"))
  }
  const openModalSettingsApp = () => {
    dispatch(setModalData(null))
    dispatch(setModal("SettingsApp"))
  }
  const swichTokens = () => {
    dispatch(setTokenSend(tokenReceived))
    dispatch(setTokenReceived(tokenSend))
  }
  const getReservesRouter = async (_routerSwap, retryCount = 0) => {
    setRouterErrors(null);

    let ContractPeriphery = PeripheryContract(provider, signer);
    // retry for when the request takes more than 2 seconds
    let retry
    if (retryCount < 10) {
      retry = setTimeout(() => getReservesRouter(_routerSwap, retryCount + 1), 2000)
    }
    if (_routerSwap.length > 1) {
      let reserves = {}
      for (let index = 0; index < _routerSwap.length - 1; index++) {
        let asset0 = _routerSwap[index];
        let asset1 = _routerSwap[index + 1];
        let functions = ContractPeriphery.functions
        try {
          let pairAddress = (await functions.get_pair({ tokenA: asset0.address, tokenB: asset1.address })).result

          let pair = CoreContract(pairAddress.value, provider, signer);
          let { result: _result } = await pair.functions.get_reserves();
          reserves[`${asset0.address}/${asset1.address}`] = _result
          setReserve(reserves[`${asset0.address}/${asset1.address}`])
          // auto cancel retry          
          if (retry) clearTimeout(retry)
        } catch (error) {
          console.log(error)
          if (error.toString().includes("undefined")) {
            setRouterErrors("Pair-Does-Not-Exist")
            console.log("Pair does not exist")
            clearTimeout(retry)
          }
        }
      }
      setRouterReserves(reserves);
    }
  }
  const getRouter = () => {
    if (t_reserve.current) {
      clearTimeout(t_reserve.current);
    }
    let _getRouter = async (refresh = false) => {
      if (tokenSend != null && tokenReceived != null) {
        let router = [tokenSend, tokenReceived]
        setRouterSwap(router);
        getReservesRouter(router);
        // set refresh route and reserves
        let _refresh = () => _getRouter(true);
        t_reserve.current = setTimeout(_refresh, refreshTimingReserve);
      } else {
        setRouterSwap([])
      }
    }
    _getRouter(false);
  }
  const amountsIn = async (amountPrev) => {
    let amount = maxDecimal(amountPrev, tokenSend.decimals);
    let _amountIn = koilibUtils.parseUnits(amount, tokenSend.decimals);
    setRouterErrors(null);
    if (tokenReceived) {
      try {
        let { result, impact } = await calculateAmountsIn({
          amountIn: _amountIn,
          reserves: routerReserves,
          paths: routerSwap,
        })
        let { fee } = await calculateAmountsOut({
          amountOut: result,
          reserves: routerReserves,
          paths: routerSwap,
        })
        let feeInput = new BigNumber(_amountIn).minus(fee).toFixed(0, 1);
        let _feeF = tokenSend.decimals != "0" ? koilibUtils.formatUnits(feeInput, tokenSend.decimals) : feeInput;
        let _amountF = tokenReceived.decimals != "0" ? koilibUtils.formatUnits(result, tokenReceived.decimals) : result;
        let _impactF = new BigNumber(impact).times("100").toFixed(2)
        let _exchangeF = (_amountF / amount).toFixed(tokenReceived.decimals)
        setFeeImpact(_feeF)
        setInputReceived(_amountF)
        setPriceImpact(_impactF)
        setExchangeRate(_exchangeF)
      } catch (error) {
        if (error.toString().includes("undefined")) {
          setRouterErrors("Pair-Does-Not-Exist")
          console.log("Pair does not exist")
        } else {
          setRouterErrors(error);
          console.log(error)
        }
        setInputReceived("")
        setPriceImpact("0")
        setExchangeRate("")
      }
    }
    setLoading(false);
  }
  const amountsOut = async (amountPrev) => {
    let amount = maxDecimal(amountPrev, tokenReceived.decimals);
    let _amountOut = koilibUtils.parseUnits(amount, tokenReceived.decimals);
    setRouterErrors(null);
    if (tokenSend) {
      try {
        let { result, impact, fee } = await calculateAmountsOut({
          amountOut: _amountOut,
          reserves: routerReserves,
          paths: routerSwap,
        })
        let feeInput = new BigNumber(result).minus(fee).toFixed(0, 1);
        let _feeF = tokenSend.decimals != "0" ? koilibUtils.formatUnits(feeInput, tokenSend.decimals) : feeInput;
        let _amountF = tokenSend.decimals != "0" ? koilibUtils.formatUnits(result, tokenSend.decimals) : result;
        let _impactF = new BigNumber(impact).times("100").toFixed(2)
        let _exchangeF = (amount / _amountF).toFixed(tokenSend.decimals)
        setFeeImpact(_feeF)
        setInputSend(_amountF)
        setPriceImpact(_impactF)
        setExchangeRate(_exchangeF)
      } catch (error) {
        if (error.toString().includes("undefined")) {
          setRouterErrors("Pair-Does-Not-Exist")
          console.log("Pair does not exist")
        } else {
          setRouterErrors(error);
          console.log(error)
        }
        setInputSend("")
        setPriceImpact("0")
        setExchangeRate("")
      }
    }
    setLoading(false);
  }

  const cleanState = () => {
    // TODO change last typed asset (TypeSwap) value to the other input field and calc all fields new
    setTypeSwap(null)
    setInputSend("")
    setPriceImpact("0");
    setExchangeRate("0");
    setInputReceived("");
  }
  const changeReserves = () => {
    if (typeSwap != null && Object.keys(routerReserves).length != 0) {
      if (typeSwap == "SWAP-IN" && inputSend) amountsIn(inputSend)
      if (typeSwap == "SWAP-OUT" && inputReceived) amountsOut(inputReceived)
    }
  }
  const debounceFnIn = (value) => {
    setLoading(true);
    setInputSend(value);
    setTypeSwap("SWAP-IN")
    if (debounceIn) clearTimeout(debounceIn);
    let _submit = () => amountsIn(value)
    let fncDelayed = setTimeout(_submit, debounceTiming);
    setDebounceIn(fncDelayed)
  }
  const debounceFnOut = (value) => {
    setLoading(true);
    setInputReceived(value)
    setTypeSwap("SWAP-OUT")
    if (debounceOut) clearTimeout(debounceOut);
    let _submit = () => amountsOut(value)
    let fncDelayed = setTimeout(_submit, debounceTiming);
    setDebounceOut(fncDelayed)
  }
  const loadInit = () => {
    initSwap();
    dispatch(setTokenSend(tokenSend))
    dispatch(setTokenReceived(tokenReceived))
    if (t_reserve.current) clearTimeout(t_reserve.current);
  }

  const initSwap = async () => {
    // swap configs
    let swaps = await InitializeSwaps();
    dispatch(InitializeSwapsRD(swaps));
  }
  const finalSwap = async () => {
    setLoading(true)

    // config contract
    let ContractPeriphery = PeripheryContract(provider, signer);
    ContractPeriphery.options.onlyOperation = true;

    // get functions
    let functions = ContractPeriphery.functions;
    let walletAddress = signer.getAddress();
    let _params = {
      path: routerSwap.map(route => route.address),
      from: walletAddress,
      receiver: walletAddress
    };
    let configs = {
      payer: walletAddress
    };

    try {
      let account_rc = await provider.getAccountRc(walletAddress);
      let rc_limit = calculateDefaultMana(account_rc)
      configs.rc_limit = rc_limit;
    } catch (error) {
      console.log(error)
      setLoading(false)
      Snackbar.enqueueSnackbar("Error in mana calculation", { variant: "error" });
      return;
    }

    let operations = [];
    // add approval to operation
    try {

      if (_get(routerSwap[0], "allowance", true)) {
        let address_token = routerSwap[0].address;
        if (_get(CONFIG_BASE, 'chain.nameservice', []).indexOf(address_token) != -1 && _get(CONFIG_BASE, 'chain.contracts.nameservice', "") != "") {
          let ns = NameServiceContract(provider, signer)
          let result = await ns.functions.get_address({ name: address_token });
          address_token = _get(result, 'result.value.address', '')
        }
        let _token = TokenContract(address_token, provider, signer);
        _token.options.onlyOperation = true;
        const approvalParams = {
          owner: walletAddress,
          spender: ContractPeriphery.getId(),
          value: koilibUtils.parseUnits(maxDecimal(inputSend, tokenSend.decimals), tokenSend.decimals)
        }
        let { operation: approveSales } = await _token.functions.approve(approvalParams, configs)
        operations.push(approveSales);
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      Snackbar.enqueueSnackbar("Approval error", { variant: "error" });
      return;
    }

    // swap tokens
    if (typeSwap === "SWAP-IN") {
      let send = koilibUtils.parseUnits(maxDecimal(inputSend, tokenSend.decimals), tokenSend.decimals);
      let received = koilibUtils.parseUnits(maxDecimal(inputReceived, tokenReceived.decimals), tokenReceived.decimals);
      let params = {
        amountIn: send,
        amountOutMin: new BigNumber(received).times(new BigNumber(slippage).div(100)).minus(received).times(-1).toFixed(0, 1),
        ..._params
      }
      try {
        let { operation: swapIn } = await functions.swap_tokens_in(params, configs);
        operations.push(swapIn)
      } catch (error) {
        console.log("ERROR SWAP-IN:", error)
        Snackbar.enqueueSnackbar("Error in swap in", { variant: "error" });
        setLoading(false);
        return;
      }
    } else {
      let send = koilibUtils.parseUnits(maxDecimal(inputSend, tokenSend.decimals), tokenSend.decimals);
      let received = koilibUtils.parseUnits(maxDecimal(inputReceived, tokenReceived.decimals), tokenReceived.decimals);
      let params = {
        amountOut: received,
        amountInMax: new BigNumber(send).times(new BigNumber(slippage).div(100)).plus(send).toFixed(0, 1),
        ..._params
      }
      try {
        let { operation: swapOut } = await functions.swap_tokens_out(params, configs);
        operations.push(swapOut)
      } catch (error) {
        console.log("ERROR SWAP-OUT:", error)
        Snackbar.enqueueSnackbar("Error in swap out", { variant: "error" });
        setLoading(false);
        return;
      }
    }
    let transaction = null;
    let receipt = null;
    try {
      const tx = await signer.prepareTransaction({ operations: operations, header: configs });
      const { transaction: _transaction, receipt: _receipt } = await signer.sendTransaction(tx);
      transaction = _transaction
      receipt = _receipt
      Snackbar.enqueueSnackbar("Transaction sent", { variant: "info" });
    } catch (error) {
      Snackbar.enqueueSnackbar("Error sending transaction", { variant: "error" });
      console.log(error)
      setLoading(false);
      return;
    }

    if (receipt.logs) {

      // console.log(receipt.logs.join(", "))
    }
    // wait to be mined
    await waitTransation(provider, transaction)

    // console.log("transaction submitted.");
    // console.log(JSON.stringify(_tx));
    Snackbar.enqueueSnackbar("Transaction successful", { variant: "success" });
    dispatch(setSwapsTiming(Date.now()))
    setLoading(false);
    cleanState()
    getRouter() // reserves are updated after swapping
  }
  // functions component
  const disabledButton = () => {
    if (!tokenSend || !tokenReceived) return true;
    if (routerErrors) return true;
    if (loading) return true;
    let inputSendPrev = maxDecimal(inputSend != "" ? inputSend : "0", tokenSend.decimals);
    let _inputSend = koilibUtils.parseUnits(inputSendPrev, tokenSend.decimals)
    if (new BigNumber(_inputSend).isGreaterThan(balanceSend)) return true;
    if (!inputSend) return true;
    return false;
  }
  const messageButton = () => {
    if (!tokenSend || !tokenReceived) return "Select a token"
    if (routerErrors === "Pair-Does-Not-Exist") return "Pair does not exist";
    if (routerErrors === "Finite") return "Insufficient liquidity"
    if (routerErrors === "NotRoute") return "Invalid pair"
    if (routerErrors === "ExceedSwap") return "Exceeded Liquidity"
    if (loading) return "Loading"
    if (new BigNumber(inputSend).isNaN()) return "Enter an amount"
    let inputSendPrev = maxDecimal(inputSend != "" ? inputSend : "0", tokenSend.decimals);
    let _inputSend = koilibUtils.parseUnits(inputSendPrev, tokenSend.decimals)
    if (new BigNumber(_inputSend).isGreaterThan(balanceSend)) return `insufficient ${_get(tokenSend, "symbol", "")} balance`
    return "Swap";
  }
  const minAndMax = () => {
    let result = "0"
    if (_get(walletSelector, "wallet", null)) {
      let send = koilibUtils.parseUnits(inputSend != "" ? inputSend : "0", tokenSend.decimals);
      let received = koilibUtils.parseUnits(inputReceived != "" ? inputReceived : "0", tokenReceived.decimals);
      if (typeSwap === "SWAP-IN") {
        let amount = new BigNumber(received).times(new BigNumber(slippage).div(100)).minus(received).times(-1).toFixed(0, 1);
        result = tokenSend.decimals != "0" ? koilibUtils.formatUnits(amount, tokenSend.decimals) : amount;
      } else {
        let amount = new BigNumber(send).times(new BigNumber(slippage).div(100)).plus(send).toFixed(0, 1);
        result = tokenReceived.decimals != "0" ? koilibUtils.formatUnits(amount, tokenReceived.decimals) : amount;
      }
    }
    return result;
  }

  const PriceImpactWarning = () => {
    const impact = new BigNumber(priceImpact)
    const warningLevel = impact.isGreaterThanOrEqualTo("15") ? (impact.isGreaterThanOrEqualTo("30") ? "error" : "warning") : "info"
    if (warningLevel !== "info") {
      return (
        <Alert
          variant="outlined"
          sx={{ "marginY": "10px" }}
          severity={warningLevel}
        >
          Price impact warning ~{impact.toString()}%
        </Alert>)
    }
  }

  //efects
  useEffect(loadInit, [])
  useEffect(getRouter, [tokenSend, tokenReceived])
  useEffect(cleanState, [tokenSend, tokenReceived])
  useEffect(changeReserves, [routerReserves])

  if (!_get(CONFIG_BASE, "contracts.periphery.launched", false)) {
    return <Maintenance />
  }

  const handleFinalSwap = () => {
    if (new BigNumber(priceImpact).isGreaterThanOrEqualTo("30")) {
      dispatch(setModalData({
        priceImpact, finalSwap
      }))
      dispatch(setModal("priceimpact-swap"))
    } else {
      finalSwap()
    }
  }


  return (
    <Box sx={{ width: "100%", justifyContent: "center", display: "flex", alignItems: "center", marginBottom: "50px" }}>
      <Card variant="outlined" sx={{ maxWidth: "500px", width: "100%", marginX: "auto", borderRadius: "10px", padding: { xs: "0px", sm: "15px 20px" } }}>
        <CardHeader
          title="SWAP"
          action={
            <Box>
              <IconButton aria-label="refresh" color="secondary" disabled={!tokenSend || !tokenReceived} onClick={() => getRouter()}>
                <HistoryIcon />
              </IconButton>
              <IconButton aria-label="settings" color="secondary" onClick={() => openModalSettingsApp()}>
                <SettingsIcon />
              </IconButton>
            </Box>
          }
          sx={{ paddingBottom: "4px" }}
        />
        <CardContent>
          <TokenInputPanel
            token={tokenSend}
            inputVal={inputSend}
            onSelect={() => openModalSelectToken({ reduce: "swap", value: "send" })}
            onBalance={(balance) => setBalanceSend(balance)}
            onInput={(amount) => debounceFnIn(amount)}
          />
          <Box sx={{ marginBottom: "10px", justifyContent: "center", display: "flex" }}>
            <IconButton aria-label="settings" sx={{ padding: "2px" }} onClick={() => swichTokens()}>
              <SwapVerticalCircleIcon color="secondary" sx={{ fontSize: 35 }} />
            </IconButton>
          </Box>
          <TokenInputPanel
            token={tokenReceived}
            inputVal={inputReceived}
            onSelect={() => openModalSelectToken({ reduce: "swap", value: "received" })}
            onBalance={(balance) => setBalanceReceived(balance)}
            onInput={(amount) => debounceFnOut(amount)}
          />
          <Box sx={{ marginTop: "1.5em" }}>
            {
              inputSend && inputReceived ? (
                <PriceImpactWarning impactValue={priceImpact} />
              ) : null
            }

            {
              !_get(walletSelector, "wallet", null) ?
                <Button variant="contained" sx={{ boxShadow: "0", width: "100%", borderRadius: "10px" }} onClick={() => dispatch(setModal("Connect"))}>
                  Connect
                </Button>
                : null
            }

            {
              _get(walletSelector, "wallet", null) ?

                < Button
                  variant="contained"
                  sx={{ width: "100%", borderRadius: "10px" }}
                  onClick={() => handleFinalSwap()}
                  disabled={disabledButton()}
                >
                  {loading ? <CircularProgress color="background" size={20} sx={{ marginRight: "10px" }} /> : null}
                  {messageButton()}
                </Button>
                : null
            }



          </Box>
          <Box>
            <Divider sx={{ marginY: "10px" }} />
            {
              _get(walletSelector, "wallet", null) && routerSwap.length && Object.keys(routerReserves).length && typeSwap && inputSend && inputReceived ?
                <React.Fragment>
                  <Box sx={{ justifyContent: "space-between", display: "flex", }}>
                    <Typography variant="caption">Price Impact</Typography>
                    <Typography variant="caption">~{priceImpact.toString()}%</Typography>
                  </Box>
                  <Box sx={{ justifyContent: "space-between", display: "flex", }}>
                    <Tooltip placement="top" title="Slippage - the difference between the expected price of an asset and the actual price at which the trade is executed.">
                      <Typography variant="caption">Slippage</Typography>
                    </Tooltip>
                    <Typography variant="caption">{slippage}%</Typography>
                  </Box>
                  <Box sx={{ justifyContent: "space-between", display: "flex", }}>
                    <Typography variant="caption">Exchange rate</Typography>
                    <Typography variant="caption">{(exchangeRate != "0" ? `1 ${tokenSend.symbol} ~ ${exchangeRate} ${tokenReceived.symbol}` : '')}</Typography>
                  </Box>
                  <Box sx={{ justifyContent: "space-between", display: "flex", }}>
                    <Typography variant="caption">Fee</Typography>
                    <Typography variant="caption">{(feeImpact != "0" ? `~ ${feeImpact} ${tokenSend.symbol}` : '')}</Typography>
                  </Box>
                  <Box sx={{ justifyContent: "space-between", display: "flex", }}>
                    <Typography variant="caption">{typeSwap === "SWAP-IN" ? "Minimum received" : "Maximum send"}</Typography>
                    <Typography variant="caption">~ {`${minAndMax()} ${tokenReceived.symbol}`}</Typography>
                  </Box>
                </React.Fragment>
                : null
            }
          </Box>
        </CardContent>
      </Card>
    </Box >
  )
}

export default SwapPage;
