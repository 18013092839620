import React from "react";
import { useNavigate } from "react-router-dom";

// components
import { Box, Typography, Divider, Button, SvgIcon } from "@mui/material"
// icons
import HomeIcon from "@mui/icons-material/Home";

const ShipComponent = (props) => (
  <SvgIcon {...props} version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
      <path d="M4705 5113 c-380 -20 -835 -119 -1205 -261 -255 -98 -360 -156 -360
      -197 0 -29 24 -64 50 -75 24 -10 38 -7 102 20 40 17 119 51 176 75 l102 44 78
      -112 c256 -371 608 -724 946 -949 140 -93 131 -73 80 -195 -454 -1083 -1384
      -1906 -2506 -2218 l-131 -37 -414 415 -415 414 37 131 c244 879 806 1650 1575
      2161 150 100 166 121 139 174 -16 30 -28 37 -66 37 -49 0 -320 -185 -538 -369
      -182 -153 -493 -481 -599 -632 l-15 -22 -78 32 c-181 72 -328 97 -521 88 -313
      -16 -588 -141 -805 -366 -301 -313 -407 -752 -285 -1176 31 -107 119 -281 192
      -376 71 -93 105 -116 149 -100 30 12 40 28 56 96 28 112 103 204 209 253 52
      25 67 27 177 26 93 0 132 -5 173 -20 l53 -19 -85 -68 c-196 -156 -352 -385
      -415 -609 -24 -85 -19 -115 25 -139 20 -10 34 -9 85 5 34 9 64 15 66 12 2 -2
      -13 -39 -35 -82 -55 -110 -105 -242 -137 -358 -30 -109 -27 -131 20 -162 24
      -16 30 -16 93 1 119 31 244 77 361 132 63 29 116 51 118 49 2 -2 -3 -29 -12
      -61 -20 -69 -9 -108 36 -124 60 -21 271 62 427 166 95 64 235 196 302 287 l44
      58 20 -53 c15 -42 20 -81 20 -174 1 -110 -1 -125 -25 -176 -51 -109 -134 -177
      -258 -211 -110 -30 -131 -88 -54 -156 217 -191 544 -305 832 -289 319 17 599
      144 818 369 39 41 75 86 78 99 14 56 -37 109 -89 93 -11 -4 -60 -46 -107 -94
      -274 -276 -647 -378 -1024 -280 -85 23 -243 93 -285 127 l-24 21 42 21 c98 50
      200 185 237 314 22 76 22 220 0 315 -9 41 -13 77 -9 82 5 4 61 22 124 40 354
      99 780 296 1077 498 l62 42 21 -47 c12 -27 33 -91 47 -143 22 -82 26 -116 27
      -250 1 -161 -8 -223 -54 -354 -25 -74 -25 -87 1 -120 27 -34 77 -36 106 -3 46
      50 92 241 100 413 9 189 -20 356 -89 521 l-31 74 22 15 c65 46 251 205 352
      303 719 687 1149 1610 1219 2611 13 192 13 373 -1 403 -6 14 -22 30 -36 36
      -22 10 -212 12 -368 4z m265 -205 c-1 -202 -35 -513 -81 -742 -30 -145 -104
      -424 -118 -438 -9 -10 -223 144 -356 257 -144 121 -378 362 -488 500 -130 165
      -211 284 -195 289 394 125 770 188 1151 195 l87 1 0 -62z m-3510 -1451 c52
      -14 117 -34 143 -46 l48 -21 -39 -58 c-208 -305 -414 -752 -507 -1097 -15 -55
      -31 -104 -35 -109 -4 -4 -41 0 -82 9 -95 22 -239 22 -315 0 -128 -37 -264
      -139 -313 -235 -11 -22 -24 -40 -29 -40 -17 0 -120 224 -141 305 -90 349 -11
      693 222 965 157 182 389 309 643 351 93 15 301 3 405 -24z m331 -2356 c-74
      -96 -193 -210 -279 -265 -71 -46 -165 -96 -181 -96 -5 0 2 35 15 78 13 42 24
      87 24 100 0 29 -42 72 -70 72 -12 0 -64 -25 -117 -56 -163 -96 -434 -217 -450
      -200 -17 16 105 288 207 459 27 47 50 95 50 107 0 29 -44 70 -74 70 -14 0 -59
      -11 -101 -24 -41 -13 -75 -20 -75 -15 0 16 50 110 96 181 54 85 168 204 264
      279 l75 57 337 -335 336 -336 -57 -76z"/>
      <path d="M3141 4095 c-255 -58 -478 -240 -574 -470 -48 -115 -61 -181 -61
      -320 0 -149 19 -236 80 -357 59 -118 159 -235 261 -306 162 -114 410 -167 603
      -128 178 36 316 112 437 240 373 392 261 1032 -222 1270 -49 25 -117 52 -150
      61 -84 23 -292 29 -374 10z m297 -151 c232 -46 421 -221 494 -454 34 -111 32
      -273 -6 -387 -65 -197 -217 -349 -421 -419 -69 -24 -96 -28 -195 -28 -133 0
      -193 14 -313 76 -277 144 -411 480 -311 778 107 317 424 501 752 434z"/>
      <path d="M3161 3855 c-88 -25 -189 -84 -255 -151 -187 -186 -219 -481 -76
      -702 102 -158 257 -248 445 -259 227 -13 433 105 534 306 51 103 65 172 58
      291 -11 228 -147 413 -364 497 -86 33 -256 42 -342 18z m245 -149 c222 -59
      358 -286 300 -501 -20 -77 -53 -133 -112 -192 -160 -160 -419 -160 -580 1
      -161 162 -162 420 -1 580 108 108 253 149 393 112z"/>
    </g>
  </SvgIcon>
)


const Soon = () => {
  // hooks
  const navigate = useNavigate();

  return (
    <Box sx={{ maxWidth: "400px", marginX: "auto", backgroundColor: "background", paddingY: 8 }}>
      <ShipComponent color="text" sx={{ fontSize: 80, display: "flex", marginX: "auto", marginY: 4 }} />
      <Typography variant="h3" textAlign="center" gutterBottom component="div">Coming Soon</Typography>
      <Divider />
      <Typography variant="subtitle2" mt={2} textAlign="center" gutterBottom component="div">We are currently working to build new features.</Typography>
      <Button
        variant="outlined"
        color="text"
        onClick={() => navigate("/")}
        sx={{ display: "flex", marginX: "auto", marginTop: 4, borderRadius: "10px" }}
        startIcon={<HomeIcon />}
      >
        Home
      </Button>
    </Box>
  )
};

export default Soon;