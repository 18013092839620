import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { get as _get, sortBy as _sortBy  } from "lodash";
import { LiquidityPositionController } from "../../services/LiquidityPositionController"
import { PairController } from "../../services/PairController";

// components mui
import { Alert, Box, useTheme, Card, Button, Collapse, Container, Link, CardHeader, CardContent, Typography, CircularProgress, Accordion, AccordionSummary, AccordionDetails } from "@mui/material"


// icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";

// components
import Maintenance from "../../components/Maintenance";

// Actions
import { setModal } from "../../redux/actions/modals"

// contracts
import { PeripheryContract, CoreContract } from "../../helpers/contracts";

// helpers
import { calculateAmount, calculatePercent, orderTokens } from "../../helpers/calculate";

// utils
import { loadTokenData } from "../../utils/token";

// constants
import { CHAINS_IDS } from "../../constants/chains"
import { CONFIG_BASE } from "../../constants/configs"
import BigNumber from "bignumber.js";
import { ConnectButton } from "../../components/Buttons/ConnectButton";

const Liquidity = () => {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  // selectors
  const networkSelector = useSelector(state => state.settings.network);
  const useAPISelector = useSelector(state => state.settings.useapi);
  const walletSelector = useSelector(state => state.wallet);
  const base_tokens = useSelector((state) => state.tokens.base_tokens);
  const custom_tokens = useSelector((state) => state.tokens.custom_tokens);
  const liquidityPositions = useSelector((state) => state.liquidity.positions);

  // state
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pairs, setPairs] = useState([])
  const [apiPositions, setAPIPositions] = useState([])
  const [apiError, setApiError] = useState(false)

  // variables
  const provider = _get(walletSelector, "provider", null)
  const signer = _get(walletSelector, "signer", null)

  // contracts
  const ContractPeriphery = PeripheryContract(provider, signer);

  // functions
  const importPosition = () => {
    dispatch(setModal("ImportPosition"))
  }
  const closeWallet = () => {
    setPositions([])
  }
  const loadPools = async () => {
    setLoading(true)
    if (networkSelector == CHAINS_IDS.UNSUPPORTED) {
      setLoading(false)
      return;
    }
    let functions = ContractPeriphery.functions;
    try {
      let address = _get(walletSelector, "wallet[0].address", "")
      let positions = []

      for (let index = 0; index < liquidityPositions.length; index++) {
        let position = liquidityPositions[index];
        // orden tokens
        try {
          let _tokens = orderTokens({ address: _get(position, "tokenA", "") }, { address: _get(position, "tokenB", "") });
          let pairTokens = {
            tokenA: _get(_tokens, "token0.address", ""),
            tokenB: _get(_tokens, "token1.address", "")
          }
          let pairAddress = (await functions.get_pair(pairTokens)).result
          if (pairAddress) {
            let pair = CoreContract(pairAddress.value, provider, signer);
            let balance = (await pair.functions.balance_of({ owner: address })).result;
            if (_get(balance, "value", "0") != "0") {
              let supply = (await pair.functions.total_supply()).result;
              let reserves = (await pair.functions.get_reserves()).result;
              // find tokenA
              let tokenAData = base_tokens.concat(custom_tokens).find(token => token.address === _get(_tokens, "token0.address", ""))
              if (!tokenAData) {
                tokenAData = await loadTokenData(_get(_tokens, "token0.address", ""))
              }
              // find tokenB
              let tokenBData = base_tokens.concat(custom_tokens).find(token => token.address === _get(_tokens, "token1.address", ""))
              if (!tokenBData) {
                tokenBData = await loadTokenData(_get(_tokens, "token1.address", ""))
              }
              positions.push({
                owner: address,
                totalSupply: _get(supply, "value", "0"),
                shares: _get(balance, "value", "0"),
                reserves: reserves,
                tokenA: tokenAData,
                tokenB: tokenBData
              });
            }
          }
        } catch (error) {
          console.log(error)
        }
      }
      setPositions(positions);
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  /**
   * initPositions
   * aggregates the liquidity data of a user from the koindx API
   * 
   * @param {*} positions 
   * @param {*} pairs 
   * @returns void
   */
  const initPositions = async (positions, pairs) => {
    let newPositionList = []
    for (let index = 0; index < positions.length; index++) {
      const pos = positions[index];
      let obj = {}
      /* get pairId if the pair id belongs to the position and the protocolId is koindx-v2 */
      const pair = pairs.find((el) => (el.id === pos.pairId && el.protocolId === CONFIG_BASE.contracts.periphery.address))
      if (pair) {
        /* find tokens from stored list */
        const token0_base = base_tokens.concat(custom_tokens).find((el) => el.address === pair.token0.id)
        const token1_base = base_tokens.concat(custom_tokens).find((el) => el.address === pair.token1.id)
        pair.token0.logoURI = _get(token0_base, "logoURI", null)
        pair.token1.logoURI = _get(token1_base, "logoURI", null)

        /* Total Supply + 10000( fee charged when the initial liquidity mining is done, this is part of uniswap v2 ) */
        const reserveUSD = new BigNumber(pair.reserveUSD);
        const totalPoolSupply = new BigNumber(pair.totalSupply);
        const userTotalLiquidityTokenPosition = new BigNumber(pos.liquidityTokenBalance);
        const token0Reserve = new BigNumber(pair.reserve0);
        const token1Reserve = new BigNumber(pair.reserve1);
        /* User token calculation (user liquidity*token reserve)/total liquidity */
        const poolShare = userTotalLiquidityTokenPosition.multipliedBy(100).dividedBy(totalPoolSupply);
        // calc dollar value of user tokens in the pool
        const usdShare = userTotalLiquidityTokenPosition.multipliedBy(reserveUSD).dividedBy(totalPoolSupply);
        /* calc % the user owns of the pool */
        const token0liquidity = userTotalLiquidityTokenPosition.multipliedBy(token0Reserve).dividedBy(totalPoolSupply);
        const token1liquidity = userTotalLiquidityTokenPosition.multipliedBy(token1Reserve).dividedBy(totalPoolSupply);

        obj.pairId = pair.id
        obj.userTotalLiquidityTokenPosition = userTotalLiquidityTokenPosition
        obj.usdShare = usdShare
        obj.poolShare = poolShare
        obj.totalPoolSupply = totalPoolSupply
        obj.token0 = pair.token0
        obj.reserve0 = token0Reserve
        obj.token0Price = new BigNumber(pair.token0Price)
        obj.token1 = pair.token1
        obj.reserve1 = token1Reserve
        obj.token1Price = new BigNumber(pair.token1Price)
        obj.token0liquidity = token0liquidity
        obj.token1liquidity = token1liquidity
        newPositionList.push(obj)
      }
    }
    setAPIPositions(newPositionList.sort((p1, p2) => {
      if (p1.usdShare.isGreaterThan(p2.usdShare)) {
        return -1;
      }
      if (p1.usdShare.isLessThan(p2.usdShare)) {
        return 1;
      }
      return 0;
    }))
    setLoading(false)
  }

  const loadPoolsApi = async () => {
    // get liquidity position
    let { LiquidityPosition, error: lpErrors } = await LiquidityPositionController.getLiquidityPositionByUserId(_get(walletSelector, "wallet[0].address", ""))
    if (lpErrors) {
      console.log(lpErrors)
      setApiError(true)
      setLoading(false)
    } else {
      // get pairs
      let { Pair, error: poolErrors } = await PairController.getPairsByIdWithToken(LiquidityPosition.map(pos => pos.pairId))
      if (Pair.length && LiquidityPosition.length) {
        // set pools and lp
        setPairs(Pair)
        setPositions(LiquidityPosition)
      } else {
        console.log(poolErrors)
        console.log(lpErrors)
        setLoading(false)
      }
    }

  }

  // effects
  useEffect(() => {
    setLoading(true)
    if (_get(walletSelector, "wallet", null)) {
      if (useAPISelector) {
        loadPoolsApi();
      } else {
        loadPools();
      }
    } else {
      closeWallet()
    }
  }, [_get(walletSelector, "wallet[0].address", ""), useAPISelector])

  useEffect(() => {
    if (positions.length && pairs.length) {
      initPositions(positions, pairs)
    }
  }, [base_tokens.concat(custom_tokens).length, positions.length, pairs.length])


  // component
  const TokenLogo = (srcPath) => {
    if (srcPath.srcPath) {
      return (
        <img src={srcPath.srcPath} alt="Token icon" style={{ height: "100%", margin: "5px" }} />
      )
    } else {
      return (
        <HelpRoundedIcon color="primary" fontSize="large" sx={{ height: "100%", width: "auto", margin: "5px" }}
          alt="token"
        />
      )
    }
  }

  const PositionCollapsable = ({ el }) => {
    const [toggle, setToggle] = useState(false)
    function toggleHandler() {
      setToggle(!toggle)
    }

    return (
      <Box sx={{ mt: ".75em", width: "100%", height: "auto", backgroundColor: "background.light", }} borderRadius={"10px"}>
        <Box onClick={toggleHandler} sx={{ padding: { xs: "0px", sm: "10px 0px 10px 10px", md: "10px 0px 10px 10px" }, display: "flex", justifyContent: "space-around", "&:hover": { cursor: "pointer" }, height: "100px", maxHeight: "100px" }}>
          <Box sx={{ px: "10px", display: "flex", justifyContent: "flex-start", alignItems: "center", width: { xs: "100%", sm: "60%", md: "60%" } }}>
            {/* token images */}
            <Box sx={{ display: { xs: "none", sm: "flex" }, width: "140px", flexDirection: "row", alignItems: "center", height: "60%" }}>
              <TokenLogo srcPath={_get(el, "token0.logoURI", null)} />
              <TokenLogo srcPath={_get(el, "token1.logoURI", null)} />
            </Box>
            {/* token symbols */}
            <Box sx={{ alignItems: "center", paddingLeft: "10px" }}>
              <Typography variant="h5" sx={{ textTransform: "uppercase" }}>{_get(el, "token0.symbol", "?")} - {_get(el, "token1.symbol", "?")}</Typography>
            </Box>

          </Box>
          {/* LP values */}
          <Box sx={{ alignItems: "center", display: { xs: "none", sm: "inline-flex" }, width: { xs: "100%", sm: "40%", md: "20%" } }}>
            <Typography>{el.userTotalLiquidityTokenPosition.toString()}</Typography>
          </Box>
          {/* USD values */}
          <Box sx={{ alignItems: "center", display: { xs: "none", sm: "none", md: "inline-flex" }, width: { xs: "100%", sm: "0%", md: "20%" } }}>
            <Typography>${el.usdShare.toFixed(4, 0)}</Typography>
          </Box>
          <Box sx={{ alignItems: "center", display: "inline-flex", paddingRight: "10px" }}>
            {
              toggle ?
                <ExpandLessIcon sx={{ color: "primary.main" }} size="large" />
                :
                <ExpandMoreIcon sx={{ color: "primary.main" }} size="large" />
            }
          </Box>

        </Box>
        <Box sx={{ backgroundColor: "background.default", px: "10px", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
          <Collapse in={toggle}>

            <Box sx={{ padding: "10px", paddingY: "15px", display: "flex", paddingRight: { xs: "0px", sm: "25px", md: "25px" }, flexDirection: { xs: "column",  sm: "row", md: "row" } }}>
              <Box sx={{ display: "flex", width:  { xs: "100%", sm: "60%", md: "60%" }, justifyContent: "space-around", flexDirection: { xs: "column", md: "row" } }} >
                <Box sx={{ padding: "0px", flexDirection: { xs: "row", sm: "column", md: "row" }, width: { xs: "100%", sm: "100%", md: "100%" }, display: { xs: "inline-block", sm: "flex", md: "flex" }, justifyContent: "flex-start", alignItems: { xs: "center", sm: "flex-start", md: "center" } }}>
                  <Box display={"flex"} sx={{ flexDirection: "column", gap: "10px", justifyContent: "center" }}>
                    <Button variant="contained" size="small" component="a" onClick={() => navigate(`/liquidity/add`)}>Add Liquidity</Button>
                    <Button variant="contained" size="small" component="a" onClick={() => navigate(`/liquidity/remove/${_get(el, "token0.id", "")}/${_get(el, "token1.id", "")}`)}>Remove Liquidity</Button>
                  </Box>
                  <Box display={"flex"} sx={{ flexDirection: "column", marginLeft: { xs: "0px", sm: "0px", md: "10px" }, marginTop: { xs: "0px", sm: "10px", md: "0px" } }}>
                    <Link target="_blank" sx={{ marginTop: { xs: "10px", sm: "0px" } }} component="a" underline="hover" href={`https://koiner.app/addresses/${el.pairId}`} title="View Pool at Koiner">View at Koiner</Link>
                    <Typography>Share of the pool: {el.poolShare.toFixed(2, 0)}%</Typography>
                    <Typography>Total pool supply: {el.totalPoolSupply.toFixed(8, 1)}</Typography>
                    <Typography sx={{ display: { xs: "inline-flex", sm: "none" } }}>Total Value LP: ${el.usdShare.toFixed(4, 1)}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", width:  { xs: "100%", sm: "40%", md: "40%" }, justifyContent: "space-around", flexDirection: { xs: "column", md: "row" }}}>
                <Box sx={{ padding: "0px", alignItems: "center", display: { xs: "none", sm: "inline-flex" }, width: { xs: "100%", sm: "100%", md: "50%" }, justifyContent: "flex-start" }}>
                    <Box display={"flex"} sx={{ flexDirection: "column" }}>
                      <Typography fontWeight={600}>Tokens Supply:</Typography>
                      <Typography sx={{ textTransform: "uppercase" }}>{el.token0.symbol}: { el.token0.decimals > 0 ? el.reserve0.toFixed(el.token0.decimals, 1) : el.reserve0.toString() }</Typography>
                      <Typography sx={{ textTransform: "uppercase" }}>{el.token1.symbol}: { el.token1.decimals > 0 ? el.reserve1.toFixed(el.token1.decimals, 1) : el.reserve1.toString() }</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ padding: "0px", alignItems: "center", display: "inline-flex", width: { xs: "100%", sm: "100%", md: "50%" }, marginTop: { xs: "0px", sm: "10px", md: "0px" }, justifyContent: "flex-start" }}>
                    <Box display={"flex"} sx={{ flexDirection: "column" }}>
                      <Typography sx={{ display: { xs: "none", sm: "inline-flex" } }} fontWeight={600}>Your Position</Typography>
                      <Typography sx={{ textTransform: "uppercase" }}>{el.token0.symbol}: { el.token0.decimals > 0 ? el.token0liquidity.toFixed(el.token0.decimals, 1) : el.token0liquidity.toString()}</Typography>
                      <Typography sx={{ textTransform: "uppercase" }}>{el.token1.symbol}: { el.token1.decimals > 0 ? el.token1liquidity.toFixed(el.token1.decimals, 1) : el.token1liquidity.toString()}</Typography>
                    </Box>
                  </Box>
              </Box>
            </Box>
          </Collapse>
        </Box>

      </Box>
    )

  }

  const DetailsPosition = (position) => (
    <Box>
      <Box sx={{ justifyContent: "space-between", display: "flex", }}>
        <Typography noWrap>Pooled {_get(position, "tokenA.symbol", "")}</Typography>
        <Typography noWrap>{calculateAmount(position, "A")}</Typography>
      </Box>
      <Box sx={{ justifyContent: "space-between", display: "flex", }}>
        <Typography noWrap>Pooled {_get(position, "tokenB.symbol", "")}</Typography>
        <Typography noWrap>{calculateAmount(position, "B")}</Typography>
      </Box>
      <Box sx={{ justifyContent: "space-between", display: "flex", }}>
        <Typography noWrap>Share of pool</Typography>
        <Typography noWrap>{calculatePercent(position)}%</Typography>
      </Box>
      <Button
        color="error"
        variant="contained"
        sx={{ marginTop: "10px", width: "100%", borderRadius: "10px" }}
        onClick={() => navigate(`/liquidity/remove/${_get(position, "tokenA.address", "")}/${_get(position, "tokenB.address", "")}`)}
      >
        Remove
      </Button>
    </Box>
  )

  const WithoutAPIComponent = () => (
    <Box sx={{ width: "100%", justifyContent: "center", display: "flex", alignItems: "center", paddingTop: "15px" }}>
      <Card variant="outlined" sx={{ maxWidth: "500px", width: "100%", marginX: "auto", borderRadius: "10px", padding: { xs: "0px", sm: "15px 20px" } }}>
        <CardHeader
          title="LIQUIDITY"
          sx={{ paddingBottom: "4px" }}
        />
        <CardContent style={{ paddingBottom: "16px" }}>
          {
            !_get(walletSelector, "wallet", null) ?
              <Typography variant="body1">Connect your wallet to show your pools or add a new one.</Typography>
              : (
                <Box sx={{ marginTop: "15px" }}>
                  <Button
                    variant="contained"
                    disabled={networkSelector == CHAINS_IDS.UNSUPPORTED}
                    sx={{ width: "100%", borderRadius: "10px" }}
                    aria-label="add"
                    onClick={() => importPosition()}
                  >
                    Import
                  </Button>
                  <Button
                    variant="contained"
                    disabled={networkSelector == CHAINS_IDS.UNSUPPORTED}
                    sx={{ marginTop: "10px", width: "100%", borderRadius: "10px" }}
                    aria-label="add"
                    onClick={() => navigate("/liquidity/add")}
                  >
                    Add
                  </Button>
                </Box>)
          }
          {
            _get(walletSelector, "wallet", null) && !loading ? (
              positions.length === 0 ?
                <Card variant="outlined" sx={{ marginTop: "10px" }}>
                  <CardContent sx={{ paddingBottom: "16px !important" }}>
                    <Typography variant="subtitle2" textAlign={"center"}>No Liquidity Positions</Typography>
                  </CardContent>
                </Card>
                : (
                  <Box sx={{ marginTop: ".5em" }}>
                    {
                      positions.map((position, index) => (
                        <Accordion
                          variant="outlined"
                          key={`position-${index}`}
                          square
                          sx={{
                            marginY: "10px",
                            borderRadius: "10px",
                            ":before": { opacity: 0 },
                            backgroundColor: "background.light"
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                            sx={{ margin: "0px" }}
                            aria-controls={"position-" + index}
                            id={"position-" + index}
                          >
                            <Typography>{`${_get(position, "tokenA.symbol", "")}/${_get(position, "tokenB.symbol", "")}`}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {DetailsPosition(position)}
                          </AccordionDetails>
                        </Accordion>
                      ))
                    }
                  </Box>
                )
            ) : null
          }

          {
            loading ?
              <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                <CircularProgress sx={{ marginBottom: "40px" }} />
              </Box>
              : null
          }
        </CardContent>
      </Card>
    </Box>
  )

  const LiquidityFromApi = () => (
    <Container sx={{ maxWidth: "2140px" }}>
      <Card sx={{ "width": "100%", padding: "1em" }}>
        <CardHeader
          title={"Your Liquidity"}
          sx={{ paddingBottom: "4px", textTransform: "uppercase" }}
        />
        {
          apiPositions.length === 0 ? (
            <Box sx={{ paddingX: "16px", marginTop: ".5em" }}>
              <Typography my={"1em"} component={"p"}>It seems like the connected wallet address does not provide liquidity at the moment.</Typography>
              <Button variant="contained" size="large" component="a" onClick={() => navigate(`/liquidity/add`)}>Add Liquidity</Button>
            </Box>
          )
            :
            (
              <Box>
                <Box sx={{ paddingX: "16px", marginTop: ".5em", display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
                  <Button
                    sx={{ height: "36px" }}
                    variant="contained"
                    size="small"
                    component="a"
                    onClick={() => navigate(`/liquidity/add`)}>Add Liquidity</Button>
                </Box>
                <Box sx={{ mt: "2em", display: "flex", padding: "15px", paddingRight: "25px" }}>
                  {

                    <>
                      <Box display={"inline-flex"} sx={{ width: { xs: "100%", sm: "60%" } }}>
                        <Typography>Pool</Typography>
                      </Box>
                      <Box sx={{ display: { xs: "none", sm: "inline-flex", md: "inline-flex" }, width: { xs: "100%", sm: "40%", md: "20%" } }}>
                        <Typography>LP Token</Typography>
                      </Box>
                      <Box sx={{ display: { xs: "none", sm: "none", md: "inline-flex" }, width: { xs: "100%", sm: "0%", md: "20%" } }}>
                        <Typography>LP Value</Typography>
                      </Box>
                    </>
                  }
                </Box>
                {apiPositions.map((el, i) => (
                  <PositionCollapsable el={el} key={i} />
                ))}
              </Box>
            )}
      </Card>
    </Container >
  )

  if (!_get(CONFIG_BASE, "contracts.periphery.launched", false)) {
    return <Maintenance />
  }

  return (
    <Box sx={{ marginBottom: { xs: "80px", md: "20px" } }}>
      {
        useAPISelector ?
          (!_get(walletSelector, "wallet", null)) ?
            <Box sx={{ maxWidth: "400px", margin: "auto" }}>
              <Card sx={{ "width": "100%", padding: "1em" }}>
                <CardHeader
                  title={"Your Liquidity"}
                  sx={{ textTransform: "uppercase" }}
                />
                <Box padding={"0px 16px 16px 16px"}>
                  <Typography sx={{ marginBottom: "1em" }}>Connect your wallet to see your active liquidity pools.</Typography>
                  <ConnectButton />
                </Box>
              </Card>
            </Box>
            :
            !loading ?
              (
                apiError == true ?
                  <Card sx={{ padding: "1em", maxWidth: "400px", marginX: "auto", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <CardHeader
                      title={"Your Liquidity"}
                      sx={{ paddingBottom: "4px", textTransform: "uppercase" }}
                    />
                    <Alert variant="outlined" severity="error" sx={{ color: "white", marginTop: "1em", marginBottom: ".5em" }}>
                      Could not load KoinDX API. Deactivate "Use KoinDX API" in the settings.
                    </Alert>
                  </Card>
                  :
                  <LiquidityFromApi />

              )
              :
              <Card sx={{ padding: "1em", maxWidth: "400px", marginX: "auto", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                <CircularProgress color="primary" sx={{ marginY: "1.5em" }} />
                <CardHeader
                  title={"Loading..."}
                  sx={{ paddingBottom: "4px", textTransform: "uppercase" }}
                />
              </Card>

          :
          <WithoutAPIComponent />
      }
    </Box >
  )
}


export default Liquidity
