import { get as _get } from "lodash"
import { TokenContract } from "./../helpers/contracts"

export const loadTokenData = async (address) => {
  const contract = await TokenContract(address)
  if (contract) {
    let token = {
      decimals: await contract.functions.decimals(),
      name: await contract.functions.name(),
      symbol: await contract.functions.symbol(),
      address: address,
    }
    token.decimals = _get(token, "decimals.result.value", 0)
    token.name = token.name.result.value
    token.symbol = token.symbol.result.value
    token.logoURI = ""
    return token;
  }
}

export const maxDecimal = (amount, decimals) => {
  let dc = amount.split(".");
  if (Array.isArray(dc) && dc.length >= 2) {
    return dc[0] + "." + dc[1].substring(0, decimals)
  }
  return amount;
}