import {SET_DISCONNECT_WALLET, SET_WALLET, SET_NAME, SET_PROVIDER, SET_SIGNER, SET_CONNECTED, SET_TOKEN_BALANCE, SET_INSTANCE } from "../constants"

// Actions
export const setWallet = (data) => ({
  type: SET_WALLET,
  payload: data
})

export const setWalletName = (data) => ({
  type: SET_NAME,
  payload: data
})

export const setProvider = (data) => ({
  type: SET_PROVIDER,
  payload: data
})

export const setSigner = (data) => ({
  type: SET_SIGNER,
  payload: data
})

export const setConnected = (data) => ({
  type: SET_CONNECTED,
  payload: data
})

export const setInstance = (data) => ({
  type: SET_INSTANCE,
  payload: data
})

export const setTokenBalance = (contract, value) => ({
  type: SET_TOKEN_BALANCE,
  payload: {
    "contract": contract,
    "value": value
  }
})

export const disconnectWallet = () => ({
  type: SET_DISCONNECT_WALLET
})