import { SET_ASSET_ONE, SET_ASSET_TWO, SET_POSITIONS } from "../constants";

// helper
import LS from "../../helpers/storage";

// Constants
const baseState = {
  assetOne: null,
  assetTwo: null,
  positions: []
};

let initialState = baseState;

try {
  let custom_positions = JSON.parse(LS.getItemDevice("custom_positions"));
  if (custom_positions) {
    initialState = Object.assign(baseState, { positions: custom_positions })
  } else {
    let base_positions = [
      { "tokenA": "1FaSvLjQJsCJKq5ybmGsMMQs8RQYyVv8ju", "tokenB": "1H88naibGSwCbxnXB3MpYSdiEChKducag3" },
      { "tokenA": "1FaSvLjQJsCJKq5ybmGsMMQs8RQYyVv8ju", "tokenB": "1M6NjRHh5x926wZUXYUz86x6j5MBqQJAvQ" },
      { "tokenA": "1H88naibGSwCbxnXB3MpYSdiEChKducag3", "tokenB": "1M6NjRHh5x926wZUXYUz86x6j5MBqQJAvQ" }
    ]
    LS.custom_positions(base_positions);
    initialState = Object.assign(baseState, { positions: base_positions })
  }
} catch (error) {
  initialState = baseState;
}

// Reducer
export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ASSET_ONE:
      return { ...state, assetOne: action.payload };
    case SET_ASSET_TWO:
      return { ...state, assetTwo: action.payload };
    case SET_POSITIONS:
      return { ...state, positions: action.payload };
    default:
      return state;
  }
}
