import { get as _get } from "lodash"
import { BaseController } from "./BaseController";

class GlobalController extends BaseController {
  static tableName = 'Global';

  static async getBlockHeight() {
      let { data: Block, error } = await this.supabase.from(this.tableName).select('*').eq('name', 'head_block').limit(1).single()
      return { Block, error }
    }
  
  static subscribeToTable(handleInserts) {
    this.supabase
      .channel('global-table')
      .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: this.tableName }, handleInserts)
      .subscribe()
  }
}

export { GlobalController }