import React from "react";
import { get as _get } from "lodash";
import { setBlockHeight } from "./redux/actions/dexdata";
import { useDispatch } from "react-redux";
import AppRouter from "./AppRouter";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";
import { GlobalController } from "./services/GlobalController";

const App = () => {
  const dispatch = useDispatch();
  GlobalController.subscribeToTable((data) => { dispatch(setBlockHeight(_get(data, 'new.value'), null)) })
  return (
    <AppRouter />
  )
};

export default App;
