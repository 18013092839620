import {advertisements} from "../constants/advertisement"

/**
 * returns the active listing
 * @param {String} slot 
 */
export const getActiveAdvertisement = (slot) => {
  if(slot.length == 0) {
    return null
  }
  // check if there is an advertisement that matches the critera
  return advertisements.filter((ad) => {
    let timestamp = new Date().getTime()
    return (new Date(ad.from).getTime() < timestamp && new Date(ad.until).getTime() > timestamp && ad.slot == slot)
  })
}