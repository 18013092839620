import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { get as _get } from "lodash";
// components mui
import { Box, Modal, Button } from "@mui/material";

// icons
import AddIcon from "@mui/icons-material/Add";

// Actions
import { setModalData, setModal } from "../../../redux/actions/modals";
import { setPositions } from "../../../redux/actions/liquidity";
import { setImportAssetOne, setImportAssetTwo } from "../../../redux/actions/positions";

// components
import TokenInputPanel from "../../TokenSelectPanel";
import ModalHeader from "./../ModalHeader";

// helpers
import LS from "./../../../helpers/storage";

const ModalImportToken = () => {
  // hooks
  const dispatch = useDispatch();

  // selectors
  const currentModal = useSelector((state) => state.modals.modal);
  // const currentModalData = useSelector((state) => state.modals.data);
  // positions
  const importAssetOne = useSelector((state) => state.positions.assetOne);
  const importAssetTwo = useSelector((state) => state.positions.assetTwo);
  const positions = useSelector((state) => state.liquidity.positions);

  // functions
  const openModalSelectToken = (data) => {
    dispatch(setModalData(data))
    dispatch(setModal("SelectToken"))
  }
  const closeModal = () => {
    dispatch(setModal(null));
    dispatch(setModalData(null));
    dispatch(setImportAssetOne(null))
    dispatch(setImportAssetTwo(null))
  };

  const importPosition = () => {
    let position = {
      tokenA: _get(importAssetOne, "address", ""),
      tokenB: _get(importAssetTwo, "address", "")
    }
    let checkPosiution = positions.find((p) =>
      (p.tokenA === position.tokenA && p.tokenB === position.tokenB) ||
      (p.tokenA === position.tokenB && p.tokenB === position.tokenA)
    )
    if (!checkPosiution) {
      let newPositionsArray = [...positions, position]
      // save to localStorage
      LS.custom_positions(newPositionsArray)
      dispatch(setPositions(newPositionsArray))
    }
    closeModal()
  }

  return (
    <Modal open={currentModal === "ImportPosition"} onClose={closeModal} sx={{ justifyContent: "center", display: "flex", maxHeight: "310px", marginY: "auto" }}>
      <Box sx={{
        padding: "10px",
        minWidth: "sm",
        width: "100%",
        maxWidth: "420px",
        bgcolor: "background.paper",
        borderRadius: "10px",
        border: "1px solid secondary.main",
      }}>
        <ModalHeader title={"Import position"} closeFunction={closeModal} backArrow={null} />
        <Box sx={{ paddingX: "15px", paddingY: "15px" }}>
          <Box sx={{ justifyContent: "center", display: "flex" }}>
            <TokenInputPanel
              onSelect={() => openModalSelectToken({ reduce: "position", value: "importAssetOne" })}
              token={importAssetOne}
            />
          </Box>
          <Box sx={{ marginY: "17px", justifyContent: "center", display: "flex" }}>
            <AddIcon sx={{ fontSize: 25 }} color="secondary" />
          </Box>
          <Box sx={{ justifyContent: "center", display: "flex" }}>
            <TokenInputPanel
              onSelect={() => openModalSelectToken({ reduce: "position", value: "importAssetTwo" })}
              token={importAssetTwo}
            />
          </Box>
          <Box sx={{ p: 1, marginTop: "5px" }}>
            <Button sx={{ display: "flex", width: "100%", borderRadius: "10px" }} onClick={importPosition} variant="contained">Import position</Button>
          </Box>

        </Box>
      </Box>
    </Modal>
  )
}

export default ModalImportToken;