import { get as _get } from "lodash";
import * as kondor from "kondor-js";
import { Provider } from "koilib";
// eslint-disable-next-line
import MyKoinosWallet from "@roamin/my-koinos-wallet-sdk";
import { SET_DISCONNECT_WALLET, SET_INSTANCE, SET_WALLET, SET_PROVIDER, SET_SIGNER, SET_CONNECTED, SET_NAME, SET_TOKEN_BALANCE } from "../constants"
import storage from "../../helpers/storage";
import { CONFIG_BASE } from "../../constants/configs";

import { initializeWalletConnect, WALLET_CONNECT_METHODS } from "../../helpers/wallets";

const initialState = {
  wallet: null,
  provider: null,
  signer: null,
  connected: false,
  instance: null,
  wallet_name: null,
  balance: {
    // [CONFIG_BASE.contracts.skoindx.address]: 0,
    // [CONFIG_BASE.contracts.koindx.address]: 0
  }
}

try {
  let wallet = JSON.parse(storage.getItemDevice("wallet"));
  let wallet_name = JSON.parse(storage.getItemDevice("wallet_name"));
  initialState.wallet = wallet;
  initialState.wallet_name = wallet_name;
  initialState.instance = null;
  initialState.connected = wallet ? true : false;
  if (wallet_name == "mkw") {
    const walletConnectorUrl = "https://mykw.vercel.app/embed/wallet-connector"
    const mkw = new MyKoinosWallet(walletConnectorUrl)
    mkw.connect().then(() => {
      initialState.provider = mkw.getProvider()
      initialState.signer = mkw.getSigner(_get(wallet, "[0].address", ""));
      initialState.instance = mkw
      initialState.connected = true;
    })
  }
  else if (wallet_name == "kondor") {
    const provider = new Provider([import.meta.env.VITE_RPC]);
    initialState.provider = provider
    initialState.signer = kondor.getSigner(_get(wallet, "[0].address", ""), { providerPrepareTransaction: provider });
    initialState.connected = true;
  }
  else if (wallet_name == "walletconnect") {
    const walletConnectKoinos = initializeWalletConnect();
    const chainID = CONFIG_BASE.wallet.walletconnect_network_id
    const accountsPromise = walletConnectKoinos.connect([chainID], [ ...WALLET_CONNECT_METHODS ])
    accountsPromise.then(accounts => {
      if (accounts.length) {
        const provider = new Provider([import.meta.env.VITE_RPC]);
        const signer = walletConnectKoinos.getSigner(accounts[0], provider, chainID);
        // set data 
        walletConnectKoinos.chainId = chainID
        initialState.instance = walletConnectKoinos
        initialState.provider = provider
        initialState.signer = signer
        initialState.connected = true;
      }
    })
  }
} catch (error) {
  console.log(error)
}

// Reducer
export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case SET_WALLET:
      storage.setItemDevice("wallet", JSON.stringify(action.payload))
      return { ...state, wallet: action.payload };
    case SET_PROVIDER:
      return { ...state, provider: action.payload };
    case SET_SIGNER:
      return { ...state, signer: action.payload };
    case SET_CONNECTED:
      return { ...state, connected: action.payload };
    case SET_INSTANCE:
      return { ...state, instance: action.payload };
    case SET_TOKEN_BALANCE:
      return { ...state, balance: { ...state.balance, [action.payload.contract]: action.payload.value } };
    case SET_NAME:
      storage.setItemDevice("wallet_name", JSON.stringify(action.payload))
      return { ...state, wallet_name: action.payload };
    case SET_DISCONNECT_WALLET:
      storage.removeItemDevice("wallet")
      storage.removeItemDevice("wallet_name")
      if (state.wallet_name == "walletconnect") {
        if (_get(state, "instance", null)) {
          state.instance.disconnect()
        }
      }
      state.wallet = null;
      state.provider = null;
      state.signer = null;
      state.connected = false;
      state.instance = null;
      state.wallet_name = null;
      return { ...state, wallet: null, provider: null, connected: false, instance: null, wallet_name: null }
    default:
      return state || null
  }
}
