import React, { useState } from "react"
import { BottomNavigation, BottomNavigationAction, Box } from "@mui/material"
import { useNavigate } from "react-router-dom";
import Popper from "@mui/material/Popper";
import { routes } from "../../constants/routes"
import { DropdownNav } from "../DropdownNav";

export const BottomNav = (props) => {
  const [navIndex, setNavIndex] = useState(0)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  //showMenu

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "dropdown-menu" : undefined;

  const prepareRoutes = () => {
    let routesList = []
    for (let route of routes) {
      routesList.push(<BottomNavigationAction key={route.index} onClick={() => navigate(route.route)} sx={{ color: "white" }} label={route.label.toUpperCase()} />)
    }
    routesList.push(<BottomNavigationAction key={routes.length} onClick={handleClick} sx={{ color: "white" }} label={"..."} />)
    return routesList
  }

  return (
    <Box>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <DropdownNav />
      </Popper>
      <BottomNavigation
        showLabels
        value={navIndex}
        onChange={(event, newValue) => {
          setNavIndex(newValue);
        }}
        sx={props.sx}
      >
        {
          prepareRoutes()
        }
      </BottomNavigation>
    </Box>
  )
}