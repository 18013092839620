import { get as _get } from "lodash";
import { SET_TRADING_VOLUME_DATA, SET_CAPITAL_LOCKED_DATA, SET_BLOCK_HEIGHT } from "../constants"
import { GlobalController } from "../../services/GlobalController";

// Constants
const initialState = {
  tradingVolume: "-",
  capitalLocked: "-",
  blockheight: null
}
try {
  let { Block, error: error } = await GlobalController.getBlockHeight()
  if (error) {
    throw new Error(error)
  }
  initialState.blockheight = _get(Block, "value", null)
} catch (e) {
  console.log(e)
}

// Reducer
export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TRADING_VOLUME_DATA:
      return { ...state, tradingVolume: action.payload };
    case SET_BLOCK_HEIGHT:
      return { ...state, blockheight: action.payload };
    case SET_CAPITAL_LOCKED_DATA:
      return { ...state, capitalLocked: action.payload }
    default:
      return state
  }
}
