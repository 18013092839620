import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { omit as _omit, get as _get } from "lodash";
// components mui
import { Box, Button, Modal,Typography } from "@mui/material";

// Actions
import { setModalData, setModal } from "../../../redux/actions/modals";
import { setCustomTokenData } from "../../../redux/actions/tokens"

// Local Storage
import LS from "../../../helpers/storage";

// components
import ModalHeader from "./../ModalHeader";

const ModalImportToken = () => {
  // hooks
  const dispatch = useDispatch();
  
  // selectors
  const currentModal = useSelector((state) => state.modals.modal);
  const currentModalData = useSelector((state) => state.modals.data);
  const custom_tokens = useSelector((state) => state.tokens.custom_tokens);

  // variables
  let token = _get(currentModalData, "token", null);

  // functions
  const closeModal = () => {
    dispatch(setModal(null));
    dispatch(setModalData(null));
  };
  const backModal = () => {
    dispatch(setModal("ManageToken"));
    dispatch(setModalData(_omit(currentModalData, ["token"])));
  }
  const importToken = () => {
    if(!custom_tokens || !custom_tokens.find((key) => key.address === token.address)) {
      let newStorageArray = custom_tokens ?? []
      newStorageArray = [...newStorageArray, token]
      // save to localStorage
      LS.custom_tokens(newStorageArray)
      dispatch(setCustomTokenData(newStorageArray))
    }
    dispatch(setModal("ManageToken"));
    dispatch(setModalData(_omit(currentModalData, ["token"])));
  }

  return (
    <Modal open={currentModal === "ImportToken"} onClose={closeModal} sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
      <Box sx={{ minWidth: "sm", height: "auto", width: "100%", maxWidth: "420px", marginY: "20px", bgcolor: "background.paper", borderRadius: "10px", overflowY: "auto" }}>
        <ModalHeader title={"Import token"} closeFunction={closeModal} backArrow={backModal} />
        {token ?   
          <Box sx={{ paddingX: "15px", marginBottom: "20px" }}>
            <Box sx={{ p: 1, border: "1px solid grey", borderRadius: "12px", marginY: 2, paddingY: 5  }}>
              <Typography sx={{ textAlign: "center" }} variant="body1" component="p">
                This token is not known by us or any token list we support. Make sure this is the correct token, you want to trade.
              </Typography>
              <Box sx={{ my: 1.5, textAlign:"center" }}>
                <Typography variant="heading" component="h3">
                  {token.name}
                </Typography>
                <Typography variant="heading" component="h5">
                  {token.symbol}
                </Typography>
                <Typography variant="body2" component="p">
                  {token.address}
                </Typography>
              </Box>
            </Box>
            <Box sx={{textAlign: "center", marginBottom: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography variant="body2" component="p">The token contract needs to have allowances implemented.</Typography>
            </Box>
            <Box sx={{textAlign: "center"}}>
              <Button sx={{ display: "flex", width: "100%"}} onClick={importToken} variant="contained">Import</Button>
            </Box>
          </Box>
          : ""}   
      </Box>
    </Modal>
  )
}

export default ModalImportToken;