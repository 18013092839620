/* eslint-disable */
import React from "react";
import { useEffect, useState } from "react";
import { get as _get } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BigNumber } from "bignumber.js";
import { utils as koilibUtils } from "koilib";
import { useSnackbar } from "notistack";
import { waitTransation } from "./../../utils/transactions"

// components mui
import {
  Box,
  Card,
  Paper,
  Slider,
  Divider,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  InputBase,
  Button,
  ButtonGroup,
  CircularProgress,
  Tooltip,
  CardHeader
} from "@mui/material";

// icons
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// components
import Maintenance from "../../components/Maintenance";

import { numberPattern } from "../../utils/regex";

// Actions
import { setModalData, setModal } from "../../redux/actions/modals";

// constants
import { CONFIG_BASE } from "../../constants/configs"

// contracts
import { PeripheryContract, CoreContract, TokenContract } from "../../helpers/contracts";

// helpers
import { orderTokens, calculateDefaultMana } from "../../helpers/calculate";

// utils
import { loadTokenData } from "../../utils/token";

const RemoveLiquidityPage = () => {
  // Dispatch to call actions
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const Snackbar = useSnackbar();

  // selectors
  const walletSelector = useSelector((state) => state.wallet);
  const settingsSelector = useSelector((state) => state.settings);
  const base_tokens = useSelector((state) => state.tokens.base_tokens);
  const custom_tokens = useSelector((state) => state.tokens.custom_tokens);

  // state
  const [percent, setPercent] = useState(0);
  const [position, setPosition] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  // variables
  const slippage = _get(settingsSelector, "slippage", "0.1");
  const provider = _get(walletSelector, "provider", null);
  const signer = _get(walletSelector, "signer", null);

  // functions
  const loadPools = async () => {
    setLoading(true);
    const ContractProtocol = PeripheryContract(provider, signer);
    let functions = ContractProtocol.functions;
    try {
      let address = _get(walletSelector, "wallet[0].address", "");
      let _tokens = orderTokens({ address: _get(params, "token_a", "") }, { address: _get(params, "token_b", "") });
      // process
      let pairTokens = {
        tokenA: _get(_tokens, "token0.address", ""),
        tokenB: _get(_tokens, "token1.address", "")
      }
      let pairAddress = (await functions.get_pair(pairTokens)).result
      if (pairAddress) {
        let pair = CoreContract(pairAddress.value, provider, signer);
        let balance = (await pair.functions.balance_of({ owner: address })).result;
        if (_get(balance, "value", "0") != "0") {
          let supply = (await pair.functions.total_supply()).result;
          let reserves = (await pair.functions.get_reserves()).result;
          // find tokenA
          let tokenAData = base_tokens.concat(custom_tokens).find((token) => token.address === _get(_tokens, "token0.address", ""));
          if (!tokenAData) {
            tokenAData = await loadTokenData(_get(_tokens, "token0.address", ""));
          }
          // find tokenB
          let tokenBData = base_tokens.concat(custom_tokens).find((token) => token.address === _get(_tokens, "token1.address", ""));
          if (!tokenBData) {
            tokenBData = await loadTokenData(_get(_tokens, "token1.address", ""));
          }
          setPosition({
            lpAddress: pairAddress.value,
            owner: address,
            totalSupply: _get(supply, "value", "0"),
            shares: _get(balance, "value", "0"),
            reserves: reserves,
            tokenA: tokenAData,
            tokenB: tokenBData
          });
        } else {
          throw "Not enough liquidity";
        }
      } else {
        throw "Pair does not exist";
      }
    } catch (error) {
      console.log(error);
      setErrors(error);
    }
    setLoading(false);
  };
  const closeWallet = () => {
    setPosition({});
  };
  const backPage = () => {
    navigate("/liquidity");
  };
  const openModalSettingsApp = (data) => {
    dispatch(setModalData(data));
    dispatch(setModal("SettingsApp"));
  };
  const calculatePercent = (_position, typed) => {
    let reserveA = _get(_position, "reserves.reserveA", "");
    let reserveB = _get(_position, "reserves.reserveB", "");
    let shares = _get(_position, "shares", "");
    let total = _get(_position, "totalSupply", "");
    let token = typed == "A" ? _get(_position, "tokenA", {}) : _get(_position, "tokenB", {});
    let liquidityPercent = new BigNumber(shares).times(percent / 100).toFixed(0, 1);
    let amountRemove = new BigNumber(liquidityPercent)
      .times(typed == "A" ? reserveA : reserveB)
      .div(total)
      .toFixed(0, 1);
    let slippageRemoved = new BigNumber(amountRemove).times(slippage / 100).toFixed(0, 1);
    let result = new BigNumber(amountRemove).minus(slippageRemoved).toFixed(0, 1);
    return token.decimals != "0" ? koilibUtils.formatUnits(result, token.decimals) : result;
  };

  const approvalAllowance = async (tokenLPAddress, ownerAddress, spenderAddress, amount, configs) => {
    let _token = TokenContract(tokenLPAddress, provider, signer);
    _token.options.onlyOperation = true;
    const approvalParams = {
      owner: ownerAddress,
      spender: spenderAddress,
      value: amount
    }
    let { operation: approveSales } = await _token.functions.approve(approvalParams, configs)
    return approveSales;
  }

  const getConfigs = async (_walletAddress) => {
    let configs = {
      payer: _walletAddress,
    };
    try {
      let account_rc = await provider.getAccountRc(_walletAddress);
      let rc_limit = calculateDefaultMana(account_rc);
      configs.rc_limit = rc_limit;
    } catch (error) {
      console.log(error)
      setLoading(false)
      Snackbar.enqueueSnackbar("Error in mana calculation", { variant: "error" });
      return;
    }
    return configs;
  }

  const finalRemove = async () => {
    setLoading(true);
    // config contract
    const ContractProtocol = PeripheryContract(provider, signer);
    ContractProtocol.options.onlyOperation = true;
    let functions = ContractProtocol.functions;
    let walletAddress = signer.getAddress();

    // pre data
    let lpAddress = _get(position, "lpAddress", "");
    let shares = _get(position, "shares", "");
    let reserveA = _get(position, "reserves.reserveA", "");
    let reserveB = _get(position, "reserves.reserveB", "");
    let total = _get(position, "totalSupply", "");

    // calculate
    let liquidityToRemove = new BigNumber(shares).times(percent / 100).toFixed(0, 1);
    let preAmountAMin = new BigNumber(liquidityToRemove).times(reserveA).div(total).toFixed(0, 1);
    let preAmountBMin = new BigNumber(liquidityToRemove).times(reserveB).div(total).toFixed(0, 1);
    let amountAMin = new BigNumber(preAmountAMin).minus(new BigNumber(preAmountAMin).times(slippage / 100).toFixed(0, 1)).toFixed(0, 1);
    let amountBMin = new BigNumber(preAmountBMin).minus(new BigNumber(preAmountBMin).times(slippage / 100).toFixed(0, 1)).toFixed(0, 1);

    let configs = await getConfigs(walletAddress);
    let operations = [];
    // approve allowance of lp token
    try {
      let approvalTokenA = await approvalAllowance(lpAddress, walletAddress, ContractProtocol.getId(), liquidityToRemove, configs);
      if (approvalTokenA) {
        operations.push(approvalTokenA)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      Snackbar.enqueueSnackbar("Approval token a error", { variant: "error" });
      return;
    }

    // remove liquidity
    try {
      let _params = {
        from: walletAddress,
        receiver: walletAddress,
        tokenA: _get(params, "token_a", ""),
        tokenB: _get(params, "token_b", ""),
        liquidity: liquidityToRemove,
        amountAMin: amountAMin,
        amountBMin: amountBMin,
      };
      let { operation: operation } = await functions.remove_liquidity(_params, configs);
      operations.push(operation)
    } catch (error) {
      console.log(error)
      Snackbar.enqueueSnackbar("Error in remove liquidity", { variant: "error" });
      return;
    }

    let transaction = null;
    let receipt = null;
    try {
      const tx = await signer.prepareTransaction({ operations: operations, header: configs });
      const { transaction: _transaction, receipt: _receipt } = await signer.sendTransaction(tx);
      transaction = _transaction
      receipt = _receipt

      Snackbar.enqueueSnackbar("Transaction sent", { variant: "info" });
    } catch (error) {
      console.log(error)
      Snackbar.enqueueSnackbar("Error sending transaction", { variant: "error" });
      setLoading(false);
      return;
    }

    // wait to be mined
    await waitTransation(provider, transaction)
    setLoading(false);
    Snackbar.enqueueSnackbar("Removed liquidity", { variant: "success" });
    setTimeout(() => navigate("/liquidity"), 2000)
  }

  // functions component
  const disabledButton = () => {
    if (percent <= 0) return true;
    return false;
  };
  const messageButton = () => {
    if (percent <= 0) return "Liquidity to remove is very small";
    return "Remove";
  };

  // effects
  useEffect(() => {
    if (_get(walletSelector, "wallet", null)) {
      loadPools();
    } else {
      closeWallet();
    }
  }, [_get(walletSelector, "wallet[0].address", "")]);

  // components
  const Loading = () => (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress color="primary"  sx={{ marginRight: "10px", display: "flex" }} />
    </Box>
  );

  if (!_get(CONFIG_BASE, "contracts.periphery.launched", false)) {
    return <Maintenance />
  }

  return (
    <Box sx={{ width: "100%", justifyContent: "center", display: "flex", alignItems: "center", paddingTop: "15px" }}>
      <Card variant="outlined" sx={{ maxWidth: "500px", width: "100%", marginX: "auto", borderRadius: "10px", padding: { xs: "0px", sm: "15px 20px" } }}>
        <CardActions>
          <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <IconButton aria-label="settings" onClick={() => backPage()}>
                <ArrowBackIcon color="secondary" />
              </IconButton>
              <CardHeader
                title="REMOVE LIQUIDITY"
              />
            </Box>
            <IconButton aria-label="settings" onClick={() => openModalSettingsApp()}>
              <SettingsIcon color="secondary" />
            </IconButton>
          </Box>
        </CardActions>
        <CardContent>
          {_get(walletSelector, "wallet", null) && !errors ? (
            !loading ? (
              <Box sx={{ marginX: "10px" }}>
                <Paper variant="outlined" component="form" sx={{ display: "flex", alignItems: "center", borderColor: "secondary.dark", borderRadius: "10px" }}>
                  <InputBase
                    placeholder="0"
                    type="number"
                    value={percent}
                    min={0}
                    max={100}
                    sx={{
                      ml: 1, flex: 1,
                      color: "text.main",
                    }}
                    onChange={(e) => numberPattern(e.target.value, setPercent)}
                  />
                  <Typography sx={{ mr: 1 }}>%</Typography>
                </Paper>
                <ButtonGroup sx={{ marginY: "20px", width: "100%", display: "flex" }}>
                  <Button
                    sx={{ width: "25%", borderRadius: "10px" }}
                    variant={percent == 25 ? "contained" : "outlined"}
                    onClick={() => setPercent(25)}
                    color="primary"
                  >
                    {" "}
                    25%{" "}
                  </Button>
                  <Button sx={{ width: "25%" }} variant={percent == 50 ? "contained" : "outlined"} onClick={() => setPercent(50)} color="primary">
                    {" "}
                    50%{" "}
                  </Button>
                  <Button sx={{ width: "25%" }} variant={percent == 75 ? "contained" : "outlined"} onClick={() => setPercent(75)} color="primary">
                    {" "}
                    75%{" "}
                  </Button>
                  <Button
                    sx={{ width: "25%", borderRadius: "10px" }}
                    variant={percent == 100 ? "contained" : "outlined"}
                    onClick={() => setPercent(100)}
                    color="primary"
                  >
                    {" "}
                    100%{" "}
                  </Button>
                </ButtonGroup>
                <Slider
                  value={percent}
                  min={0}
                  max={100}
                  sx={{ width: "98%", margin: "auto", display: "flex", marginY: "10px" }}
                  onChange={(e) => setPercent(e.target.value)}
                  valueLabelDisplay="auto"
                />

                <Button
                  variant="contained"
                  sx={{ width: "100%", marginY: "10px", borderRadius: "10px" }}
                  onClick={() => finalRemove()}
                  disabled={disabledButton()}
                >
                  {loading ? <CircularProgress color="primary" size={25} sx={{ marginRight: "10px" }} /> : null}
                  {messageButton()}
                </Button>

                {percent && Object.keys(position).length ? (
                  <Box>
                    <Divider sx={{ marginY: "10px" }} />
                    <Box>
                      <Box sx={{ justifyContent: "space-between", display: "flex" }}>
                        <Tooltip placement="top" title="Slippage - the difference between the expected price of an asset and the actual price at which the trade is executed.">
                          <Typography variant="caption">Slippage</Typography>
                        </Tooltip>
                        <Typography variant="caption">{slippage}%</Typography>
                      </Box>
                      <Box sx={{ justifyContent: "space-between", display: "flex" }}>
                        <Typography variant="caption">Min received {_get(position, "tokenA.symbol", {})}</Typography>
                        <Typography variant="caption">{calculatePercent(position, "A")}</Typography>
                      </Box>
                      <Box sx={{ justifyContent: "space-between", display: "flex" }}>
                        <Typography variant="caption">Min received {_get(position, "tokenB.symbol", {})}</Typography>
                        <Typography variant="caption">{calculatePercent(position, "B")}</Typography>
                      </Box>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            ) : (
              <Loading />
            )
          ) : null}

          {errors ? (
            <Box>
              <Typography> {errors} </Typography>
            </Box>
          ) : null}

          {!_get(walletSelector, "wallet", null) ? (
            <Button variant="contained" sx={{ width: "100%", color: "#efefef", borderRadius: "10px" }} onClick={() => dispatch(setModal("Connect"))}>
              Connect
            </Button>
          ) : null}
        </CardContent>
      </Card>
    </Box>
  );
};

export default RemoveLiquidityPage;
