import { SET_CUSTOM_TOKENS, INITIALIZE_TOKENS } from "../constants";

// state
let initialState = {
  loading: true,
  base_tokens: [],
  custom_tokens: []
}

// Reducer
export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOM_TOKENS:
      return { ...state, custom_tokens: action.payload };
    case INITIALIZE_TOKENS:
      return { ...action.payload };
    default:
      return state;
  }
}
