import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { omit as _omit, get as _get } from "lodash";
// components mui
import { Box, Button, Modal, Typography } from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
// Actions
import { setModalData, setModal } from "../../../redux/actions/modals";

// components
import ModalHeader from "../ModalHeader";

const ModalPriceImpactSwap = () => {
  // hooks
  const dispatch = useDispatch();

  // selectors
  const currentModal = useSelector((state) => state.modals.modal);
  const currentModalData = useSelector((state) => state.modals.data);

  // variables
  const finalSwap = _get(currentModalData, "finalSwap", null);
  const priceImpact = _get(currentModalData, "priceImpact", null);

  // functions
  const closeModal = () => {
    dispatch(setModal(null));
    dispatch(setModalData(null));
  };

  const confirmSwap = () => {
    finalSwap()
    closeModal()
  }


  return (
    <Modal open={currentModal === "priceimpact-swap"} onClose={closeModal} sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
      <Box sx={{ padding: "0.5em", height: "auto", width: "100%", maxWidth: "490px", marginY: "20px", bgcolor: "background.paper", borderRadius: "10px", overflowY: "auto" }}>
        <ModalHeader title={"Price Impact Warning"} closeFunction={closeModal} backArrow={closeModal} />
        {finalSwap && priceImpact ?
          <Box sx={{ paddingX: "15px", marginBottom: "20px" }}>
            <Box sx={{ borderRadius: "12px", marginY: 1.5, padding: "2em 1em", display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
              <WarningAmberIcon color="warning" fontSize="large" />
              <Typography sx={{ textAlign: "center" }} variant="h6" component="p">
                The price impact is ~{priceImpact}%
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center", marginBottom: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography variant="body2" component="p">The price impact is high, do you want to swap anyway?</Typography>
            </Box>
            <Box sx={{ textAlign: "center", display: "flex", justifyContent: "space-around", marginTop: "1em", marginBottom: ".75em" }}>
              <Button sx={{ display: "flex" }} onClick={closeModal} variant="outlined">Cancel</Button>
              <Button sx={{ display: "flex" }} onClick={confirmSwap} variant="contained">Swap Now</Button>
            </Box>
          </Box>
          : ""}
      </Box>
    </Modal>
  )
}

export default ModalPriceImpactSwap;