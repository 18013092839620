import React from "react";
import { get as _get } from "lodash";
// components
import { Box, Paper, Button, Typography, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";

const TokenNotExist = ({ onSelect }) => {
  return <Button onClick={onSelect}><Typography variant="body1" component="span">Select a token</Typography></Button>;
};

const TokenExist = ({ token, onSelect }) => {
  const symbol = _get(token, "symbol", "");
  return (
    <Button sx={{ height: "36px" }} onClick={() => onSelect()}>
      {
        _get(token, "logoURI", "") != "" ?
          <img
            src={
              _get(token, "logoURI", "")
            }
            style={{ width: "30px", height: "30px", borderRadius: "50%" }}
            alt="token"
          />
          :
          <HelpRoundedIcon color="primary" style={{ width: "30px", height: "30px", borderRadius: "50%" }}
            alt="token"
          />
      }
      <Typography variant="bodyInput" sx={{ marginLeft: "6px" }} component="span">
        {symbol}
      </Typography>
    </Button>
  );
};

const copyTokenAddress = async token => {
  try {
    await navigator.clipboard.writeText(token.address);
  } catch (err) {
    console.log(err)
  }
};

const TokenInputPanel = ({ token, onSelect }) => {
  return (
    <Box sx={{ marginBottom: "10px" }}>
      <Paper variant="contained" component="form" sx={{ p: "2px 4px", display: "flex", alignItems: "center", borderRadius: "10px" }}>
        {!token ? <TokenNotExist onSelect={() => onSelect()} /> : <TokenExist token={token} onSelect={onSelect} />}
        {token !== null && token.name !== "Koinos" ? <IconButton aria-label="delete" size="small" onClick={() => { copyTokenAddress(token) }}>
          <ContentCopyIcon fontSize="inherit" color="secondary" />
        </IconButton> : ""}
      </Paper>
    </Box>
  );
};

export default TokenInputPanel;
