import React from "react";
import { useSelector } from "react-redux"
import Connect from "./Connect";
import SelectToken from "./SelectToken";
import ManageToken from "./ManageToken";
import ImportToken from "./ImportToken";
import SettingsApp from "./SettingsApp";
import ImportPosition from "./ImportPosition";
import PriceImpactSwap from "./PriceImpactSwap"

const Modals = () => {
  // selector
  const currentModal = useSelector(state => state.modals.modal);

  if (currentModal == null) {
    document.body.style.overflow = "auto"
  }

  return (
    <>
      <Connect />
      <SelectToken />
      <SettingsApp />
      <ManageToken />
      <ImportToken />
      <ImportPosition />
      <PriceImpactSwap />
    </>
  );
}

export default Modals;