import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get as _get } from "lodash";

// components mui
import { Box, IconButton, Modal, List, ListItemText, ListItemButton, ListItem, TextField, Divider, ListItemIcon, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";// Button,  ListItemButton,TextField
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
// Validation
import { regexBtcAddress } from "../../../utils/regex"

// contract
import { loadTokenData } from "../../../utils/token";

// Actions
import { setModalData, setModal } from "../../../redux/actions/modals";
import { setCustomTokenData } from "../../../redux/actions/tokens"

// Local Storage
import LS from "../../../helpers/storage";

// components
import ModalHeader from "../ModalHeader";

const ModalManageToken = () => {
  //hooks
  const dispatch = useDispatch();

  // state
  const [tokenResult, setTokenResult] = useState(null) // tokenTextField
  const [errorMessage, setErrorMessage] = useState(null) // tokenTextField

  // selectors
  const currentModal = useSelector((state) => state.modals.modal);
  const currentModalData = useSelector((state) => state.modals.data);
  const custom_tokens = useSelector((state) => state.tokens.custom_tokens);
  const base_tokens = useSelector((state) => state.tokens.base_tokens);


  // functions
  const closeModal = () => {
    dispatch(setModal(null));
    dispatch(setModalData(null));
  };
  const backModal = () => {
    dispatch(setModal("SelectToken"));
  }
  const deleteToken = (token) => {
    const newList = custom_tokens.filter(_token => _token.address != token.address);
    LS.custom_tokens(newList)
    dispatch(setCustomTokenData(newList))
  }
  const importToken = (token) => {
    // clean search token
    setTokenResult(null)
    // import token
    dispatch(setModal("ImportToken"));
    dispatch(setModalData({ ...currentModalData, token }))
  }
  const contractLookUp = async (e) => {
    setTokenResult(null)
    let address = e.target.value
    if (regexBtcAddress(address)) {
      // check token exist
      let existToken = base_tokens.concat(custom_tokens).find(_token => _token.address == address);

      if (existToken) {
        setErrorMessage("Token already exists in token list")
        setTimeout(() => {
          setErrorMessage(null)
        }, 5000);
        return;
      }
      // import token from contract
      const result = await loadTokenData(address)
      setTokenResult(result)
    }
  }

  return (
    <Modal open={currentModal === "ManageToken"} onClose={closeModal} sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
      <Box sx={{ minWidth: "sm", maxHeight: "900px", width: "100%", maxWidth: "420px", marginY: "20px", bgcolor: "background.paper", borderRadius: "10px", overflowY: "auto" }}>
        <ModalHeader title={"Manage Tokens"} closeFunction={closeModal} backArrow={backModal} />
        <Box sx={{ marginX: "15px" }}>
          <Box sx={{paddingTop: "1em"}}>
            <TextField
              id="outlined-search"
              label="Add custom token"
              type="text"
              size="small"
              onChange={contractLookUp}
              sx={{
                width: "100%", "& .MuiInputBase-root": {
                  color: "text.main", borderRadius: "10px", "& fieldset": {
                    borderColor: "secondary.main"
                  }, "&:hover fieldset": {
                    borderColor: "primary.main",
                  }
                }
              }} />
            {
              tokenResult ? (
                <Box>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemButton onClick={() => importToken(tokenResult)}>
                      <ListItemText primary={<Typography variant="body1" component="p">{_get(tokenResult, "name", "")}</Typography>} secondary={<Typography color="secondary" variant="body2" component="p">{_get(tokenResult, "address", "")}</Typography>} />
                    </ListItemButton>
                  </ListItem>
                </Box>
              ) : null
            }
            {errorMessage ? <Typography variant="subtitle2" color="" sx={{ marginY: "20px", textAlign: "center" }}>{errorMessage}</Typography> : null}
            {
              tokenResult ? <Divider /> : null
            }
          </Box>
          <Box>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>

              {custom_tokens.map((token, key) => (
                <ListItem key={key} sx={{}}
                  secondaryAction={
                    <IconButton edge="end" color="secondary" aria-label="comments" onClick={() => { deleteToken(token) }}>
                      <DeleteIcon />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemIcon>
                    {
                      _get(token, "logoURI", "") != "" ?
                        <img
                          src={
                            _get(token, "logoURI", "")
                          }
                          style={{ width: "40px", height: "40px" }}
                          alt="token"
                        />
                        :
                        <HelpRoundedIcon color="primary" style={{ width: "40px", height: "40px" }}
                          alt="token"
                        />
                    }
                  </ListItemIcon>
                  <ListItemText primary={<Typography variant="body1" component="p">{_get(token, "name", "")}</Typography>} secondary={<Typography color="secondary" variant="body2" component="p">{_get(token, "address", "").slice(0, 25) + "..."}</Typography>} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ModalManageToken;